import { FC, memo } from 'react';
import type { MDXRemoteSerializeResult } from 'next-mdx-remote';
import HydrateMdx from '@components/HydrateMdx';
import InternalLink from '@components/InternalLink';
import Disclaimer from '@components/Disclaimer';
import * as styles from './styles';

type MarkdownSectionProps = {
  markdownContent?: MDXRemoteSerializeResult;
  applyWidthLimit?: boolean;
};

const components = { InternalLink, Disclaimer };

const MarkdownSection: FC<MarkdownSectionProps> = ({
  markdownContent,
  applyWidthLimit = false,
}) =>
  markdownContent ? (
    <div
      className={styles.prose(applyWidthLimit)}
      data-test-id="markdown-content-block"
    >
      <HydrateMdx mdx={markdownContent} components={components} />
    </div>
  ) : null;

export default memo(MarkdownSection);
