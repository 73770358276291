/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { memo, FC } from 'react';
import type { DatoProductImage, ButtonGroup } from '@framework/api/types';
import getCmsLinkUrl from '@utils/get-cms-link-url';
import { ResponsiveImage } from '@components/Imgix';
import Link from 'next/link';
import * as styles from './styles';

type Props = {
  logos?: { image?: DatoProductImage; id?: string; link?: ButtonGroup }[];
};

const Logo: FC<Props> = ({ logos }) => {
  const hasLogos = Array.isArray(logos) && logos.length > 0;

  const renderLogo = (logo: {
    image?: DatoProductImage;
    id?: string;
    link?: ButtonGroup;
  }): JSX.Element | null => {
    const link = Array.isArray(logo.link?.internalLink)
      ? logo.link?.internalLink[0]
      : logo.link?.internalLink;

    const internalLink = logo.link?.internalLink
      ? getCmsLinkUrl({
          contentType: link._modelApiKey,
          pageType: link.pageType,
          slug: link.slug,
          parentSlug: link?.parentCategory?.slug,
        })
      : null;

    if (logo.link) {
      return internalLink ? (
        <Link
          key={logo.id}
          href={internalLink}
          passHref
          className={styles.logoGridItems}
          target={logo.link.newTab ? '_blank' : undefined}
          rel={logo.link.newTab ? 'noopener noreferrer' : undefined}
        >
          <ResponsiveImage
            imageUrl={logo.image?.nonResponsiveUrl}
            imgixParams={{ h: 48, auto: 'format' }}
            urlType="srcset"
            alt={logo.image?.alt || ''}
            className={styles.logo}
          />
        </Link>
      ) : (
        <a
          href={logo.link.externalLinkRef?.url}
          className={styles.logoGridItems}
        >
          <ResponsiveImage
            imageUrl={logo.image?.nonResponsiveUrl}
            imgixParams={{ h: 48, auto: 'format' }}
            urlType="srcset"
            alt={logo.image?.alt || ''}
            className={styles.logo}
          />
        </a>
      );
    }
    return (
      <div key={logo.id} className={styles.logoGridItems}>
        <ResponsiveImage
          imageUrl={logo.image?.nonResponsiveUrl}
          imgixParams={{ h: 48, auto: 'format' }}
          urlType="srcset"
          alt={logo.image?.alt || ''}
          className={styles.logo}
        />
      </div>
    );
  };

  return hasLogos ? <>{logos.map(logo => renderLogo(logo))}</> : null;
};

export default memo(Logo);
