import { memo, FC, ReactNode } from 'react';
import cn from 'clsx';
import ContentWrapper from '@components/ContentWrapper';
import SectionWrapper from '@components/SectionWrapper';
import * as styles from './styles';

type Props = {
  children?: ReactNode;
  backgroundColor?: string;
  center?: boolean;
};

const Disclaimer: FC<Props> = ({ children, backgroundColor, center = false }) =>
  children ? (
    <SectionWrapper
      noSpacing
      className={cn(
        styles.background[backgroundColor?.toLowerCase() || 'transparent'],
        { [styles.center]: center }
      )}
    >
      <ContentWrapper
        className={styles.containerStyles(
          backgroundColor?.toLowerCase() === 'dark'
        )}
      >
        {children}
      </ContentWrapper>
    </SectionWrapper>
  ) : null;

export default memo(Disclaimer);
