import { classnames } from '@tailwindcss-classnames';

const textBase = classnames(
  'text-center',
  'w-11/12',
  'lg:max-w-screen-lg',
  'xl:max-w-screen-xl',
  '2xl:max-w-screen-2xl',
  'mx-auto',
  'md:px-6',
  'lg:px-20'
);
export const heading = classnames('h3', 'mb-3', textBase);
export const subheading = classnames(
  'md:text-xl',
  'mb-4',
  'lg:mb-12',
  textBase
);
export const productHeading = classnames('font-semibold', 'text-white', 'my-3');

const grid = classnames(
  'grid',
  'grid-cols-2',
  'md:grid-cols-3',
  'lg:grid-cols-4'
);
const gridPartial = classnames(
  'grid',
  'grid-cols-2',
  'md:flex',
  'justify-center'
);
export const productContainer = (productLength: boolean): string =>
  classnames('mt-8', {
    [gridPartial]: productLength,
    [grid]: !productLength,
  });
export const productSpacer = classnames('mt-4', 'mx-2');
export const srOnly = classnames('sr-only');

export const arrow = classnames(
  'h-8',
  'w-8',
  'md:h-12',
  'md:w-12',
  'text-white',
  'stroke-current'
);

const sliderButtonBase = classnames(
  'focus:outline-none',
  'absolute',
  'transform',
  '-translate-y-1/2',
  'top-1/2'
);

export const sliderButton = {
  left: classnames(sliderButtonBase, 'left-10', 'lg:left-65'),
  right: classnames(sliderButtonBase, 'right-10', 'lg:right-65'),
};
export const productsWrapper = classnames(
  'absolute',
  'top-0',
  'right-0',
  'w-2/6',
  'bg-opacity-40',
  'bg-nzxt-light-grey-800',
  'h-full',
  'p-2',
  'overflow-y-auto',
  'overflow-x-hidden'
);

export const productSeparated = classnames(
  'space-y-3',
  'divide-y',
  'divide-nzxt-light-grey-50',
  'divide-solid',
  'text-white'
);
