import { FC, memo } from 'react';
import type { DatoProductImage } from '@framework/api/types';
import CollectionCard from './CollectionCard';

type CollObj = {
  collectionName: string;
  collectionSlug: string;
  lowestPriceInCollection: number;
  collectPageMainImage: DatoProductImage;
  isRegionallyAvailable: boolean;
};

type Props = {
  product?: CollObj;
  dataLength: number;
};

const CollectionCardInner: FC<Props> = ({ product, dataLength }) =>
  product ? <CollectionCard dataLength={dataLength} {...product} /> : null;

export default memo(CollectionCardInner);
