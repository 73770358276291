import { memo, FC, useState, useEffect } from 'react';
import type { DatoProductImage, HeroText } from '@framework/api/types';
import Image from '@components/Image';
import ContentWrapper from '@components/ContentWrapper';
import SectionWrapper from '@components/SectionWrapper';
import TextBlock from './TextBlock';

import * as styles from './styles';

type Props = {
  heroText?: HeroText[];
  primaryAsset?: DatoProductImage;
  backgroundAsset?: DatoProductImage;
  backgroundColor?: {
    hex?: string;
  };
  startDate?: string;
  endDate?: string;
  fullBleed?: boolean;
  asH1?: boolean;
  darkText?: boolean;
  finalHours?: number;
  hideCountdown?: boolean;
};

const getSchedule = (
  current: number,
  start: number,
  end: number,
  finalHoursCount: {
    finalHoursUnix: number;
    isInFinalHours: boolean;
  },
  hasFinalPanel: boolean
): string => {
  if (
    start.valueOf() - current.valueOf() > 0 &&
    end.valueOf() - current.valueOf() > 0
  ) {
    return 'before';
  }

  if (
    start.valueOf() - current.valueOf() <= 0 &&
    end.valueOf() - current.valueOf() > 0
  ) {
    if (finalHoursCount.isInFinalHours && hasFinalPanel) {
      return 'final hours';
    }
    return 'during';
  }

  if (
    start.valueOf() - current.valueOf() <= 0 &&
    end.valueOf() - current.valueOf() <= 0
  ) {
    return 'after';
  }

  return null;
};

const CountdownHero: FC<Props> = ({
  heroText,
  primaryAsset,
  backgroundAsset,
  backgroundColor,
  startDate,
  endDate,
  fullBleed = false,
  asH1 = true,
  darkText = false,
  hideCountdown = false,
  finalHours,
}) => {
  const FINAL_HOURS = 'final hours';
  const BEFORE = 'before';
  const startDateUnix = new Date(startDate).getTime();
  const endDateUnix = new Date(endDate).getTime();
  const [schedule, setSchedule] = useState(null);
  const hasFinalHoursPanel = heroText.filter(
    item => item?.displaySchedule?.toLowerCase() === FINAL_HOURS
  );

  useEffect(() => {
    const compareFn = (): void => {
      const currentDate = new Date().getTime();
      const finalHoursUnix = finalHours > 0 ? finalHours * 3600 * 1000 : null;
      const whenFinalHoursStart = endDateUnix - (finalHoursUnix || 0);
      const isInFinalHours = currentDate >= whenFinalHoursStart;

      const finalHoursObj = {
        finalHoursUnix,
        isInFinalHours,
      };

      const now = new Date().getTime();
      setSchedule(
        getSchedule(
          now,
          startDateUnix,
          endDateUnix,
          finalHoursObj,
          hasFinalHoursPanel?.length > 0 && finalHours > 0
        )
      );
    };

    const timer = setInterval(() => compareFn(), 1000);
    return () => clearInterval(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDateUnix, finalHours, endDateUnix, hasFinalHoursPanel]);

  const hasHeroText =
    Array.isArray(heroText) && heroText?.filter(item => item?.id);

  const date = schedule === BEFORE ? startDate : endDate;

  const bgStyles = backgroundColor?.hex && {
    backgroundColor: backgroundColor?.hex,
  };

  return hasHeroText ? (
    <SectionWrapper
      data-test-id="countdown-hero-block"
      className={styles.outerWrapper(fullBleed)}
      style={bgStyles}
    >
      {backgroundAsset?.responsiveImage && (
        <div className={styles.backgroundImageContainer}>
          <Image
            data={backgroundAsset?.responsiveImage}
            loading="eager"
            decoding="async"
            className={styles.backgroundImage}
          />
        </div>
      )}
      <ContentWrapper
        className={styles.extendedContentWrapper}
        style={{ minHeight: '500px' }}
      >
        {primaryAsset?.responsiveImage && (
          <div className={styles.primaryAssetContainer}>
            <Image
              data={primaryAsset?.responsiveImage}
              loading="eager"
              decoding="async"
              className={styles.primaryAsset}
            />
          </div>
        )}
        {Array.isArray(heroText) && heroText?.length > 0 && (
          <div
            data-test-id="countdown-hero-text-block"
            className={styles.wrapper(Boolean(schedule !== null))}
          >
            {heroText?.map(item =>
              item?.displaySchedule?.toLowerCase() === schedule ? (
                <TextBlock
                  key={item?.id}
                  eyebrowLogo={item?.eyebrowLogo}
                  eyebrow={item?.eyebrow}
                  heading={item?.heading}
                  subheading={item?.subheading}
                  asH1={asH1}
                  buttons={item?.buttons}
                  date={schedule !== 'after' && date}
                  hideCountdown={
                    schedule === BEFORE || schedule === FINAL_HOURS
                      ? false
                      : hideCountdown
                  }
                  darkText={darkText}
                  hasPrimaryAsset={Boolean(primaryAsset?.responsiveImage)}
                />
              ) : null
            )}
          </div>
        )}
      </ContentWrapper>
    </SectionWrapper>
  ) : null;
};

export default memo(CountdownHero);
