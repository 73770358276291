import { classnames } from '@tailwindcss-classnames';

type PaddingClasses = {
  middle: string;
  top: string;
  bottom: string;
};

const whiteCopy = classnames('text-white');
const greyCopy = classnames('text-nzxt-light-grey-700');
const baseButton = classnames(
  'flex',
  'justify-center',
  'lg:flex-row',
  'flex-col'
);

const centerItems = classnames('items-center');
export const margin = {
  center: classnames('mx-auto'),
  left: classnames('mx-0'),
};

// mapper vals
export const topLg = classnames('lg:mb-auto', 'lg:mt-0');
export const middleLg = classnames('lg:mb-auto', 'lg:mt-auto');
export const bottomLg = classnames('lg:mb-0', 'lg:mt-auto');
export const top = classnames('mb-auto', 'mt-0');
export const middle = classnames('mb-auto', 'mt-auto');
export const bottom = classnames('mb-0', 'mt-auto');

export const positionsLg = {
  'Left/Top': top,
  'Left/Middle': middle,
  'Left/Bottom': bottom,
  'Right/Top': top,
  'Right/Middle': middle,
  'Right/Bottom': bottom,
  'Center/Top': top,
  'Center/Middle': middle,
  'Center/Bottom': bottom,
};

const leftLg = classnames('lg:pt-16', 'lg:pb-16', 'lg:pl-12');
const rightLg = classnames('lg:pt-16', 'lg:pb-16', 'lg:pl-8', 'lg:pr-20');

export const paddingLg = {
  leftTop: leftLg,
  leftMiddle: leftLg,
  leftBottom: leftLg,
  rightTop: rightLg,
  rightMiddle: rightLg,
  rightBottom: rightLg,
  centerTop: classnames(
    'lg:pt-16',
    'lg:pb-8',
    'lg:pr-0',
    'lg:pl-0',
    'lg:mb-auto',
    'lg:mx-auto',
    'lg:mt-0'
  ),
  centerMiddle: classnames(
    'lg:pt-20',
    'lg:pb-20',
    'lg:pr-0',
    'lg:pl-0',
    'lg:mx-auto'
  ),
  centerBottom: classnames(
    'lg:pt-8',
    'lg:pb-16',
    'lg:pr-0',
    'lg:pl-0',
    'lg:mb-0',
    'lg:mt-auto',
    'lg:mx-auto'
  ),
};

export const paddingLgNoImage = classnames(
  'lg:pt-20',
  'lg:pb-20',
  'lg:pl-20',
  'lg:pr-20'
);

export const paddingSm = (isShort: boolean): PaddingClasses => ({
  middle: classnames(isShort ? 'pt-8' : 'pt-12', 'pb-12', 'pl-6', 'pr-6'),
  top: classnames(
    isShort ? 'pt-8' : 'pt-12',
    isShort ? 'pb-4' : 'pb-8',
    'pl-6',
    'pr-6'
  ),
  bottom: classnames('pt-8', 'pb-12', 'pl-6', 'pr-6'),
});

export const textAlignMapper = {
  center: classnames('text-center'),
  left: classnames('text-left'),
};

// copy
export const contentContainer = classnames(
  'flex',
  'flex-col',
  'relative',
  'lg:w-[52%]'
);

// TODO: add indent
export const eyebrowLogo = classnames('max-w-[200px]', 'w-full', 'mb-3');

export const eyebrow = (isInverted: boolean): string =>
  classnames('text-sm', 'font-semibold', 'mb-3', 'indent-1', {
    [whiteCopy]: isInverted,
  });

export const heading = (isInverted: boolean): string =>
  classnames(
    'text-3xl',
    'mb-4',
    'lg:mb-5',
    'lg:text-5xl',
    'font-bold',
    'max-w-prose',
    'leading-tight',
    'lg:leading-tight',
    {
      [whiteCopy]: isInverted,
    }
  );

export const subheading = (isInverted: boolean): string =>
  classnames(
    'font-semibold',
    'text-xl',
    'mb-1',
    'lg:mb-2',
    'lg:text-3xl',
    'max-w-prose',
    {
      [whiteCopy]: isInverted,
    }
  );

export const copy = (isInverted: boolean): string =>
  classnames('max-w-prose', 'text-base', 'md:text-lg', {
    [whiteCopy]: isInverted,
  });

const linkStyles = classnames('underline', 'underline-offset-4', 'text-left');

export const footnote = (isInverted: boolean, isLink: boolean): string =>
  classnames('text-xs', 'mt-3.5', {
    [greyCopy]: !isInverted,
    [whiteCopy]: isInverted,
    [linkStyles]: isLink,
  });

export const ctaContainer = classnames('flex', 'mt-7', 'lg:mt-10');

// buttons
export const buttonWrapperLg = (isCenter: boolean): string =>
  classnames('hidden', 'lg:block', baseButton, {
    [centerItems]: isCenter,
    [margin.center]: isCenter,
  });
export const buttonWrapperSm = (isCenter: boolean): string =>
  classnames(baseButton, 'lg:hidden', 'block', {
    [centerItems]: isCenter,
    [margin.center]: isCenter,
  });
