import { classnames } from '@tailwindcss-classnames';

export const baseWrapper = classnames(
  'mx-auto',
  'grid',
  'grid-cols-12',
  'gap-4',
  'lg:gap-6',
  'text-center',
  'lg:text-left',
  'justify-center',
  'py-10',
  'md:py-0',
  'relative'
);

const darkText = classnames('text-white');
export const baseRelative = classnames('relative');
const defaultText = classnames('text-nzxt-light-grey-800');
export const backgroundImageContainer = classnames(
  'absolute',
  'bottom-0',
  'right-0',
  'left-0',
  'w-full',
  'h-full'
);

export const backgroundImage = classnames('h-full', 'object-cover');
export const outerWrapper = {
  transparent: classnames('bg-transparent', baseRelative, 'overflow-hidden'),
  light: classnames('bg-nzxt-light-grey-100', baseRelative, 'overflow-hidden'),
  dark: classnames('bg-black', baseRelative, 'overflow-hidden'),
};

export const gridItem = classnames(
  'col-span-12',
  'flex',
  'flex-col',
  'justify-start'
);

export const sectionHeading = (isInverted: boolean): string =>
  classnames(
    'font-bold',
    'leading-tight',
    'xl:leading-tight',
    'tracking-tighter',
    'text-4xl',
    'lg:text-5xl',
    {
      [darkText]: isInverted,
      [defaultText]: !isInverted,
    }
  );

export const copy = (isInverted: boolean): string =>
  classnames(
    'mt-3',
    'text-xl',
    'md:text-2xl',
    'lg:text-3xl',
    'font-semibold',
    'mb-4',
    'lg:mb-8',
    {
      [darkText]: isInverted,
      [defaultText]: !isInverted,
    }
  );

export const footnote = (isInverted: boolean): string =>
  classnames('mt-3', 'text-sm', 'font-semibold', 'block', {
    [darkText]: isInverted,
    [defaultText]: !isInverted,
  });

export const footnoteLink = classnames('underline');

export const buttonWrapper = classnames(
  'flex',
  'justify-center',
  'items-center',
  'mx-auto',
  'md:space-x-4',
  'md:space-y-0',
  'space-y-2',
  'md:flex-row',
  'flex-col'
);
export const extendedButtonStyles = classnames(
  'w-full',
  'mx-auto',
  'text-center'
);
export const extendedTextButtonStyles = classnames(
  'text-nzxt-dark-grey-500',
  'hover:text-nzxt-dark-grey-600',
  'mx-auto'
);

const topOrderImage = classnames('order-1', 'md:order-2');
const bottomOrderImage = classnames('order-1');

export const imageGridItem = (isTop: boolean): string =>
  classnames(gridItem, 'mb-4', 'sm:mb-0', {
    [topOrderImage]: isTop,
    [bottomOrderImage]: !isTop,
  });

export const widthClamp = classnames(
  'max-w-lg',
  'md:max-w-lg',
  'mx-auto',
  'lg:max-w-4xl',
  'md:px-10'
);

const topOrderText = classnames('order-2', 'md:order-1');
const bottomOrderText = classnames('order-2');

export const textGridItem = (isTop: boolean): string =>
  classnames(gridItem, 'text-center', 'px-4', {
    [topOrderText]: isTop,
    [bottomOrderText]: !isTop,
  });

const topFlow = classnames('flex-col-reverse', 'md:flex-col');
const bottomFlow = classnames('flex-col-reverse');

export const copyFlow = (isTop: boolean): string =>
  classnames('flex', 'h-full', 'w-full', {
    [topFlow]: isTop,
    [bottomFlow]: !isTop,
  });
