import { classnames } from '@tailwindcss-classnames';

const baseStyles = classnames('w-full', 'py-8');

const lightBackground = classnames('bg-nzxt-light-grey-100', baseStyles);
const darkBackground = classnames('bg-nzxt-dark-grey-800', baseStyles);
const whiteBackground = classnames('bg-white', baseStyles);
const whiteCopy = classnames('text-white');

export const background = {
  light: lightBackground,
  dark: darkBackground,
  transparent: whiteBackground,
};

export const containerStyles = (useWhiteCopy: boolean): string =>
  classnames('prose-sm', '!text-xs', {
    [whiteCopy]: useWhiteCopy,
  });

export const center = classnames(
  'flex',
  'flex-col',
  'text-center',
  'mx-auto',
  'justify-center'
);
