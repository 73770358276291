import { classnames } from '@tailwindcss-classnames';

const maxWidth = classnames(
  'w-full',
  'lg:max-w-screen-lg',
  'xl:max-w-screen-xl',
  '2xl:max-w-screen-2xl',
  'mx-auto'
);

export const outerWrapper = (isFullBleed: boolean): string =>
  classnames('overflow-hidden', 'relative', {
    [maxWidth]: !isFullBleed,
  });
export const extendedContentWrapper = classnames(
  'relative',
  'overflow-hidden',
  'flex-col',
  'pb-12',
  'lg:pb-0',
  'lg:flex-row',
  'flex',
  'justify-between',
  'items-center',
  'h-full'
);

export const backgroundImageContainer = classnames(
  'absolute',
  'bottom-0',
  'right-0',
  'left-0',
  'w-full',
  'h-full',
  'overflow-hidden'
);
export const backgroundImage = classnames('h-full', 'object-cover');

export const primaryAssetContainer = classnames('order-1', 'lg:order-2');

export const primaryAsset = classnames('w-full');

const hideCountdown = classnames('opacity-0');
const displayCountdown = classnames('opacity-100');
export const wrapper = (shouldDisplay: boolean): string =>
  classnames(
    'md:w-3/4',
    'xl:w-7/12',
    'transition-opacity',
    'duration-150',
    'ease-in',
    'order-2',
    'lg:order-1',
    {
      [displayCountdown]: shouldDisplay,
      [hideCountdown]: !shouldDisplay,
    }
  );
