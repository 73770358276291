/* eslint-disable no-underscore-dangle */
import { FC, memo } from 'react';
import { useCountdown, useTranslation as t } from '@utils/hooks';

import * as styles from './styles';

interface Props {
  isInverted?: boolean;
  date?: string;
}

const Countdown: FC<Props> = ({ date, isInverted }) => {
  const countdownDate = useCountdown(date);
  const DAYS_LABEL = t('label_days');
  const HOURS_LABEL = t('label_hours');
  const MINUTES_LABEL = t('label_minutes');
  const SECONDS_LABEL = t('label_seconds');

  return date ? (
    <div
      className={styles.countdownWrapper(countdownDate.initialized)}
      data-test-id="countdown-hero-count-down-block"
    >
      <div className={styles.countdownCell}>
        <p className={styles.countdownNumber(isInverted)}>
          {countdownDate.days}
        </p>
        <p className={styles.countdownText(isInverted)}>{DAYS_LABEL}</p>
      </div>
      <div className={styles.countdownCell}>
        <p className={styles.countdownNumber(isInverted)}>
          {countdownDate.hours}
        </p>
        <p className={styles.countdownText(isInverted)}>{HOURS_LABEL}</p>
      </div>

      <div className={styles.countdownCell}>
        <p className={styles.countdownNumber(isInverted)}>
          {countdownDate.minutes}
        </p>
        <p className={styles.countdownText(isInverted)}>{MINUTES_LABEL}</p>
      </div>

      <div className={styles.countdownCell}>
        <p className={styles.countdownNumber(isInverted)}>
          {countdownDate.seconds}
        </p>
        <p className={styles.countdownText(isInverted)}>{SECONDS_LABEL}</p>
      </div>
    </div>
  ) : null;
};
export default memo(Countdown);
