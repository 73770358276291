import { FC, memo } from 'react';
import type { MDXRemoteSerializeResult } from 'next-mdx-remote';
import type { LinkedItem } from '@framework/api/types';
import SectionWrapper from '@components/SectionWrapper';
import ContentWrapper from '@components/ContentWrapper';
import MarkdownIconBlock from './MarkdownIconBlock';
import * as styles from './styles';

type markdownIconBlocks = {
  id?: string;
  heading?: string;
  linkText?: string;
  internalLink?: LinkedItem;
  externalLink?: { url: string };
  icon?: string;
  _modelApiKey: string;
  decoratedMarkdownContent?: MDXRemoteSerializeResult;
  markdownCopy?: string;
};

export type Props = {
  markdownIconBlocks: markdownIconBlocks[];
};

const MarkdownIconBlockSection: FC<Props> = ({ markdownIconBlocks }) =>
  Array.isArray(markdownIconBlocks) && markdownIconBlocks?.length > 0 ? (
    <SectionWrapper data-test-id="markdown-icon-block">
      <ContentWrapper className={styles.contentWrapperExtended}>
        {markdownIconBlocks.map(block => (
          <MarkdownIconBlock
            key={block.id}
            heading={block.heading}
            linkText={block.linkText}
            internalLink={block.internalLink}
            externalLink={block.externalLink}
            icon={block.icon}
            decoratedMarkdownContent={block.decoratedMarkdownContent}
            markdownCopy={block.markdownCopy}
            isMoreThanOne={markdownIconBlocks.length > 1}
          />
        ))}
      </ContentWrapper>
    </SectionWrapper>
  ) : null;

export default memo(MarkdownIconBlockSection);
