import { classnames } from '@tailwindcss-classnames';

const lightText = classnames('text-white');
export const eyebrowLogo = classnames('mb-2');
export const heading = (isInverted: boolean): string =>
  classnames(
    'font-bold',
    'leading-tight',
    'xl:leading-tight',
    'tracking-tighter',
    'text-4xl',
    'lg:text-5xl',
    'mb-3',
    {
      [lightText]: !isInverted,
    }
  );
export const subheading = (isInverted: boolean): string =>
  classnames('text-base', 'md:text-lg', 'lg:text-xl', 'font-semibold', 'mb-3', {
    [lightText]: !isInverted,
  });
export const eyebrow = (isInverted: boolean): string =>
  classnames('text-sm', 'md:text-base', {
    [lightText]: !isInverted,
  });

const centerText = classnames('my-auto');
export const textBlockWrapper = (hasAsset: boolean): string =>
  classnames('lg:my-auto', 'w-full', 'relative', {
    [centerText]: !hasAsset,
  });
