import { classnames } from '@tailwindcss-classnames';

// flow mappers
export const flowMapperLG = {
  col: classnames('lg:flex-col'),
  row: classnames('lg:flex-row'),
};
export const flowMapperSM = {
  col: classnames('flex-col'),
  default: classnames('flex-row'),
};

export const fixedHeight = classnames(
  'lg:h-[75vh]',
  'lg:max-h-[768px]',
  'min-h-[550px]',
  'h-full'
);

export const minimumHeight = classnames('lg:min-h-[768px]', 'min-h-[550px]');

export const shortHeight = classnames('lg:min-h-[550px]');

export const heroHeightClasses = (
  isShort: boolean,
  relaxedHeight: boolean
): string =>
  classnames({
    [shortHeight]: isShort,
    [minimumHeight]: relaxedHeight && !isShort,
    [fixedHeight]: !relaxedHeight && !isShort,
  });

export const container = classnames(
  'relative',
  'overflow-hidden',
  'w-full',
  'mx-auto'
);

export const backgroundImage = classnames('h-full', 'w-full', 'object-cover');

export const gridWrapper = classnames(
  'flex',
  'w-full',
  'lg:max-w-screen-2xl',
  'mx-auto'
);
