/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
import { FC, Fragment, memo, useMemo, ReactNode } from 'react';
import { useMedia } from '@utils/hooks';
import cx from 'clsx';
import Image from '@components/Image';
import type {
  DatoProductImage,
  ButtonGroup,
  mediaObjectProps,
  DatoVideo,
} from '@framework/api/types';
import ContentWrapper from '@components/ContentWrapper';
import MediaObject from '@components/MediaObject';
import TextBlock from './TextBlock/TextBlock';
import * as styles from './styles';

interface Props {
  desktopVideo?: DatoVideo;
  mobileVideo?: DatoVideo;
  desktopPoster?: DatoProductImage;
  mobilePoster?: DatoProductImage;
  overlayAsset?: DatoProductImage;
  textColorTheme?: string;
  textPosition?: string;
  eyebrow?: string;
  eyebrowLogo?: DatoProductImage;
  heading?: string;
  altHeading?: string;
  bodyCopy?: string;
  loopingVideo?: boolean;
  fullBleed?: boolean;
  darkTheme?: boolean;
  logo?: DatoProductImage;
  relatedContent?: mediaObjectProps;
  buttons?: ButtonGroup[];
}

type WrapperProps = {
  children?: ReactNode;
};

const ContentWrapperFull: FC<WrapperProps> = ({ children, ...rest }) => (
  <ContentWrapper className={styles.fullBleed} {...rest}>
    {children}
  </ContentWrapper>
);

const VideoHero: FC<Props> = ({
  desktopVideo,
  desktopPoster,
  mobileVideo,
  mobilePoster,
  overlayAsset,
  textColorTheme,
  eyebrow,
  eyebrowLogo,
  heading,
  bodyCopy,
  buttons,
  loopingVideo,
  logo,
  fullBleed,
  textPosition,
  darkTheme,
  altHeading,
  relatedContent,
}) => {
  const isSm = useMedia('sm');
  const isMd = useMedia('md');

  const hasRelatedContent = Boolean(
    (Array.isArray(relatedContent) && relatedContent?.length > 0) ||
      relatedContent
  );

  const mediaObjects = Array.isArray(relatedContent)
    ? relatedContent
    : [relatedContent];

  const isSmallViewPort = isSm || isMd;

  const hasMobileVideo = Boolean(mobileVideo?.video?.mp4Url);

  const videoSource = isSmallViewPort
    ? mobileVideo?.video?.mp4Url || desktopVideo?.video?.mp4Url
    : desktopVideo?.video?.mp4Url;

  const posterSourceNoVideo = isSmallViewPort
    ? mobilePoster?.responsiveImage || desktopPoster?.responsiveImage
    : desktopPoster?.responsiveImage;

  const posterSource = isSmallViewPort
    ? desktopVideo?.video?.thumbnailUrl || desktopVideo?.video?.thumbnailUrl
    : desktopVideo?.video?.thumbnailUrl;

  const sanitizedTextPlacement = textPosition.toLowerCase().replace(' ', '');

  const sanitizedTextColor = textColorTheme.toLowerCase().replace(' ', '');
  const hasButtons = Boolean(Array.isArray(buttons) && buttons?.length > 0);

  const composedClassNameTextPosition = useMemo(
    () =>
      cx(
        styles.textPlacementBase(hasMobileVideo),
        styles.textPlacement[sanitizedTextPlacement]
      ),
    [sanitizedTextPlacement, hasMobileVideo]
  );
  const isCenterTop = sanitizedTextPlacement === 'centertop';
  const isCenterCenter = sanitizedTextPlacement === 'centercenter';

  const composedClassNameTextPositionNoVideo = useMemo(
    () =>
      cx(
        styles.textPlacementNoVideoBase(hasButtons, isCenterTop),
        styles.textPlacementNoVideo[sanitizedTextPlacement]
      ),
    [sanitizedTextPlacement, isCenterTop, hasButtons]
  );

  const fullBleedCap = fullBleed && {
    maxHeight: '75vh',
  };

  const Wrapper = !fullBleed
    ? Fragment
    : desktopVideo?.video?.mp4Url
    ? ContentWrapper
    : ContentWrapperFull;

  const hiddenH1 = !heading && {
    overflow: 'hidden',
    width: '1px',
    height: '1px',
  };

  return desktopVideo?.video?.mp4Url || desktopPoster?.responsiveImage?.src ? (
    <>
      <section
        className={styles.innerWrapper(fullBleed, hasRelatedContent)}
        style={{
          ...fullBleedCap,
          height: '75vh',
        }}
        data-test-id="video-hero-block"
      >
        {!desktopVideo?.video && posterSourceNoVideo && (
          <div className={styles.backgroundImageContainer}>
            <Image
              data={posterSourceNoVideo}
              loading="eager"
              decoding="async"
              className={styles.backgroundImage}
            />
          </div>
        )}
        {desktopVideo?.video?.mp4Url && (
          <>
            <video
              autoPlay
              key={videoSource}
              src={videoSource}
              loop={loopingVideo}
              poster={`${posterSource}?&time=01`}
              playsInline
              muted
              className={styles.video}
              style={{
                minHeight: !isSm && '500px',
                width: '100%',
                height: '100%',
              }}
            />
            {overlayAsset && hasMobileVideo && (
              <div
                className={styles.overlayAsset}
                style={{
                  backgroundImage: `url(${overlayAsset?.responsiveImage?.src})`,
                }}
              />
            )}
          </>
        )}
        <Wrapper>
          {(heading || bodyCopy) && (
            <div
              id="text-wrapper"
              className={
                desktopVideo?.video?.mp4Url
                  ? composedClassNameTextPosition
                  : composedClassNameTextPositionNoVideo
              }
            >
              <TextBlock
                eyebrow={eyebrow}
                eyebrowLogo={eyebrowLogo}
                heading={heading}
                bodyCopy={bodyCopy}
                buttons={buttons}
                textColorTheme={sanitizedTextColor}
                darkTheme={darkTheme}
                logo={logo}
                hasMobileVideo={
                  desktopVideo?.video?.mp4Url ? hasMobileVideo : true
                }
                hasBothVideos={Boolean(
                  desktopVideo?.video?.mp4Url && hasMobileVideo
                )}
                isCenter={isCenterTop || isCenterCenter}
              />
            </div>
          )}
        </Wrapper>
        {altHeading && !heading && (
          <h1 style={{ ...hiddenH1 }}>{altHeading}</h1>
        )}
      </section>
      {hasRelatedContent && (
        <div
          data-test-id="video-hero-related-content-block"
          className={cx(
            styles.innerWrapper(false, false),
            styles.supportingContent
          )}
        >
          <MediaObject mediaObjects={mediaObjects} noSpace />
        </div>
      )}
    </>
  ) : null;
};

export default memo(VideoHero);
