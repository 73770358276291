import { FC, memo } from 'react';
import type { SimpleCardsProps } from '@framework/api/types';
import LinkBlock from './LinkBlock';

import * as styles from './styles';

interface Props {
  linkColumnData: SimpleCardsProps[];
}

const LinkColumn: FC<Props> = ({ linkColumnData }) =>
  linkColumnData ? (
    <div className={styles.linkColumn}>
      <div className={styles.linkItemGrid}>
        {linkColumnData.map(linkItem => (
          <LinkBlock
            key={linkItem.heading}
            heading={linkItem.heading}
            subheading={linkItem.description}
            linkText={linkItem.linkText}
            internalLink={linkItem.internalLink}
            externalLink={linkItem.externalLink}
            regions={linkItem.availableRegion}
          />
        ))}
      </div>
    </div>
  ) : null;

export default memo(LinkColumn);
