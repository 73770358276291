import { classnames } from '@tailwindcss-classnames';

const priceDiscounted = classnames('line-through');
export const priceContainer = classnames(
  'flex',
  'w-1/2',
  'flex-col',
  'text-right',
  'h-full'
);
export const discountedPricedText = classnames('text-white');
export const isOnSale = (salePrice: boolean): string =>
  classnames({ [priceDiscounted]: salePrice });
export const contentWrapper = classnames(
  'flex-1',
  'justify-between',
  'flex',
  'flex-col',
  'w-full',
  'mt-1'
);
export const productName = classnames('text-white', 'w-1/2');
export const productDescription = classnames('text-xs', 'opacity-75');
export const anchor = classnames(
  'text-sm',
  'flex',
  'justify-between',
  'items-start',
  'my-2'
);
