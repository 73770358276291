import { FC, memo } from 'react';
import { useTranslation as t } from '@utils/hooks';
import type { MDXRemoteSerializeResult } from 'next-mdx-remote';

import Markdown from './AddressItems/MarkdownField';
import Hours from './AddressItems/Hours';
import * as styles from './styles';

type AddressColumnProps = {
  _modelApiKey?: string;
  addressField?: string;
  directorField?: string;
  phoneNumberField?: string;
  hoursField?: string;
  addressFieldMd: MDXRemoteSerializeResult;
  directorFieldMd?: MDXRemoteSerializeResult;
  phoneNumberFieldMd?: MDXRemoteSerializeResult;
  hoursFieldMd?: MDXRemoteSerializeResult;
  holidayScheduleMd?: MDXRemoteSerializeResult;
  notification?: string;
  notificationMd?: MDXRemoteSerializeResult;
};

interface Props {
  addressColumnData: AddressColumnProps;
}

const AddressColumn: FC<Props> = ({ addressColumnData }) => {
  const HEADQUARTERS = t('label_headquarters');

  return addressColumnData ? (
    <div className={styles.addressColumn}>
      <p className={styles.headingLabel}>{HEADQUARTERS}</p>
      {addressColumnData.directorFieldMd && (
        <Markdown markdown={addressColumnData.directorFieldMd} />
      )}
      <div className={styles.topAddressSection}>
        <Markdown markdown={addressColumnData.addressFieldMd} />
        <Markdown markdown={addressColumnData.phoneNumberFieldMd} />
      </div>

      <Hours hours={addressColumnData.hoursFieldMd} />
      <Markdown markdown={addressColumnData.holidayScheduleMd} />
      <Markdown markdown={addressColumnData.notificationMd} />
    </div>
  ) : null;
};

export default memo(AddressColumn);
