/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-underscore-dangle */
import { memo, FC, useMemo } from 'react';
import Image from '@components/Image';
import cx from 'clsx';
import type { DatoProductImage, ButtonGroup } from '@framework/api/types';
import { useMedia } from '@utils/hooks';
import ContentWrapper from '@components/ContentWrapper';
import SectionWrapper from '@components/SectionWrapper';
import getCmsLinkUrl from '@utils/get-cms-link-url';
import LinkGroup from '@components/LinkGroup';
import Link from 'next/link';
import * as styles from './styles';

export type HeroCenteredProps = {
  heading: string;
  description?: string;
  image?: DatoProductImage; // image is not required and should be
  textPosition?: string;
  backgroundImageLarge?: DatoProductImage;
  backgroundImageSmall?: DatoProductImage;
  background?: 'transparent' | 'light' | 'dark';
  buttons?: ButtonGroup[];
  footnote?: string;
  footnoteLinkText?: string;
  footnoteLink?: {
    _modelApiKey: string;
    pageType?: string;
    slug: string;
    parentCategory?: {
      slug: string;
    };
  };
};

// TODO: Remove check for '-'

const HeroCentered: FC<HeroCenteredProps> = ({
  heading,
  description,
  buttons,
  image,
  backgroundImageLarge,
  backgroundImageSmall,
  background,
  textPosition,
  footnote,
  footnoteLinkText,
  footnoteLink,
}) => {
  const isTop = textPosition === 'top';
  const isMd = useMedia('md');
  const isSm = useMedia('sm');

  const isSmallViewport = isMd || isSm;

  const footnoteInternalLink = footnoteLink
    ? getCmsLinkUrl({
        contentType: footnoteLink._modelApiKey,
        pageType: footnoteLink.pageType,
        slug: footnoteLink.slug,
        parentSlug: footnoteLink?.parentCategory?.slug,
      })
    : null;
  const hasFootnoteLink = !!footnoteInternalLink && !!footnoteLinkText;

  const isInverted = background === 'dark';

  const composedClassNameWrapper = useMemo(
    () =>
      cx(
        styles.outerWrapper[background],
        styles.baseRelative,
        styles.copyFlow(isTop)
      ),
    [background, isTop]
  );

  const backgroundImageId = isSmallViewport
    ? 'hero-centered-small-bgimage'
    : 'hero-centered-large-bgimage';

  return (
    <SectionWrapper
      className={composedClassNameWrapper}
      data-test-id="hero-centered-block"
      style={{ minHeight: isSmallViewport ? '520px' : '800px' }}
    >
      {(backgroundImageLarge?.responsiveImage ||
        backgroundImageSmall?.responsiveImage) && (
        <div
          className={styles.backgroundImageContainer}
          data-test-id={backgroundImageId}
        >
          {isSmallViewport ? (
            <>
              {backgroundImageSmall?.responsiveImage && (
                <Image
                  data={backgroundImageSmall?.responsiveImage}
                  loading="eager"
                  decoding="async"
                  className={styles.backgroundImage}
                />
              )}
            </>
          ) : (
            <>
              {backgroundImageLarge?.responsiveImage && (
                <Image
                  data={backgroundImageLarge?.responsiveImage}
                  loading="eager"
                  decoding="async"
                  className={styles.backgroundImage}
                />
              )}
            </>
          )}
        </div>
      )}
      <ContentWrapper>
        <div className={styles.baseWrapper}>
          <div
            className={styles.textGridItem(isTop)}
            data-test-id="hero-centered-text-block"
          >
            <h2
              className={styles.sectionHeading(isInverted)}
              data-test-id="hero-centered-heading"
            >
              {heading}
            </h2>
            {description && (
              <p
                className={styles.copy(isInverted)}
                data-test-id="hero-centered-description"
              >
                {description}
                {footnote && (
                  <span
                    className={styles.footnote(isInverted)}
                    data-test-id="hero-centered-all-footnote"
                  >
                    {footnote}{' '}
                    {hasFootnoteLink && (
                      <Link
                        href={footnoteInternalLink}
                        passHref
                        className={styles.footnoteLink}
                        data-test-id="hero-centered-footnote-link"
                      >
                        {footnoteLinkText}
                      </Link>
                    )}
                  </span>
                )}
              </p>
            )}
            {Array.isArray(buttons) && buttons?.length > 0 && (
              <div
                className={styles.buttonWrapper}
                data-test-id="hero-centered-button-block"
              >
                <LinkGroup links={buttons} lg isInverted={isInverted} />
              </div>
            )}
          </div>
          {image.responsiveImage?.alt !== '-' && (
            <div
              className={styles.imageGridItem(isTop)}
              data-test-id="hero-centered-image"
            >
              <div className={styles.widthClamp}>
                {image && (
                  <Image
                    data={image.responsiveImage}
                    loading="eager"
                    decoding="async"
                  />
                )}
              </div>
            </div>
          )}
        </div>
      </ContentWrapper>
    </SectionWrapper>
  );
};

export default memo(HeroCentered);
