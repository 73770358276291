/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-restricted-properties */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, memo } from 'react';
import { useMedia } from '@utils/hooks';
import type { DatoProductImage } from '@framework/api/types/product';
import LargeWithScroll from './LargeWithScroll';
import SmallNoScroll from './SmallNoScroll';

type Props = {
  image?: DatoProductImage;
  heading?: string;
  leadText?: string;
  bodyCopy?: string;
  darkTheme?: boolean;
};

const AnimatedZoomSection: FC<Props> = ({
  image,
  heading,
  leadText,
  bodyCopy,
  darkTheme,
}) => {
  const isMd = useMedia('md');
  const isSm = useMedia('sm');
  const isLg = !isMd && !isSm;

  return (
    <>
      {isLg ? (
        <LargeWithScroll
          image={image}
          heading={heading}
          leadText={leadText}
          bodyCopy={bodyCopy}
          isInverted={darkTheme}
        />
      ) : (
        <SmallNoScroll
          image={image}
          heading={heading}
          leadText={leadText}
          bodyCopy={bodyCopy}
          isInverted={darkTheme}
        />
      )}
    </>
  );
};

export default memo(AnimatedZoomSection);
