/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, memo } from 'react';
import cn from 'clsx';
import Link from 'next/link';
import type { MDXRemoteSerializeResult } from 'next-mdx-remote';
import type { LinkedItem } from '@framework/api/types';
import getCmsLinkUrl from '@utils/get-cms-link-url';
import HydrateMdx from '@components/HydrateMdx';
import {
  IconTestedwithCare,
  Icon2YearWarranty,
  IconExpertlyBuilt,
  IconHeart,
  IconStep1,
  IconStep2,
  IconStep3,
  IconGameSync,
  IconSmartScheduling,
  IconCompleteCustomization,
  IconRBGLEDStrips,
  IconSideGlasscasepanel,
  IconAerFanRGB,
  IconH1,
  IconKrakenZ,
  IconKrakenzpumps,
  IconKraken,
  IconLighting,
  IconRadiatorSupportLarge,
  IconRadiatorSupport,
  IconSettings2,
  IconIntegratedRearIO,
  IconPerformance2,
  IconRGBCableComb,
  IconKrakenM22,
  IconReinforcedExtendedTubing,
  IconWifi,
  IconMidTower,
  IconChevronRight,
} from '@nzxt/react-icons';

import * as styles from './styles';
import extraStyles from './extraStyles.module.css';

type Props = {
  heading?: string;
  linkText?: string;
  internalLink?: LinkedItem;
  externalLink?: { url: string };
  icon?: string;
  decoratedMarkdownContent?: MDXRemoteSerializeResult;
  markdownCopy?: string;
  isMoreThanOne?: boolean;
};

const MarkdownIconBlock: FC<Props> = ({
  heading,
  linkText,
  internalLink,
  externalLink,
  icon,
  markdownCopy,
  decoratedMarkdownContent,
  isMoreThanOne = false,
}) => {
  const hasContent = Boolean(decoratedMarkdownContent || markdownCopy);
  const internalAnchor = internalLink
    ? getCmsLinkUrl({
        contentType: internalLink._modelApiKey,
        pageType: internalLink.pageType,
        slug: internalLink.slug,
        parentSlug: internalLink?.parentCategory?.slug,
      })
    : null;
  const { buildSystem } = styles;
  const ICON_SECTION_MAP = {
    Heart: <IconHeart className={buildSystem} />,
    TestedWithCare: <IconTestedwithCare className={buildSystem} />,
    TwoYearWarranty: <Icon2YearWarranty className={buildSystem} />,
    ExpertlyBuilt: <IconExpertlyBuilt className={buildSystem} />,
    Step1: <IconStep1 className={buildSystem} />,
    Step2: <IconStep2 className={buildSystem} />,
    Step3: <IconStep3 className={buildSystem} />,
    GameSync: <IconGameSync className={buildSystem} />,
    SmartScheduling: <IconSmartScheduling className={buildSystem} />,
    RBGLEDStrips: <IconRBGLEDStrips className={buildSystem} />,
    CompleteCustomization: (
      <IconCompleteCustomization className={buildSystem} />
    ),
    SideGlasscasepanel: <IconSideGlasscasepanel className={buildSystem} />,
    AerFanRGB: <IconAerFanRGB className={buildSystem} />,
    H1: <IconH1 className={buildSystem} />,
    KrakenZ: <IconKrakenZ className={buildSystem} />,
    Krakenzpumps: <IconKrakenzpumps className={buildSystem} />,
    Kraken: <IconKraken className={buildSystem} />,
    RadiatorSupportLarge: <IconRadiatorSupportLarge className={buildSystem} />,
    RadiatorSupport: <IconRadiatorSupport className={buildSystem} />,
    Lighting: <IconLighting className={buildSystem} />,
    Settings2: <IconSettings2 className={buildSystem} />,
    RearIO: <IconIntegratedRearIO className={buildSystem} />,
    Performance2: <IconPerformance2 className={buildSystem} />,
    RGBCableComb: <IconRGBCableComb className={buildSystem} />,
    KrakenM22: <IconKrakenM22 className={buildSystem} />,
    Reinforced: <IconReinforcedExtendedTubing className={buildSystem} />,
    Wifi: <IconWifi className={buildSystem} />,
    MidTower: <IconMidTower className={buildSystem} />,
  };

  return (
    <div
      data-test-id="markdown-icon-item"
      className={styles.blockOuter(isMoreThanOne)}
    >
      {icon && ICON_SECTION_MAP[icon]}
      {heading && (
        <h2
          className={styles.heading}
          data-test-id="markdown-icon-item-heading"
        >
          {heading}
        </h2>
      )}
      {hasContent && (
        <div
          className={cn(extraStyles.markdown, styles.content)}
          data-test-id="markdown-icon-item-markdowncopy"
        >
          {decoratedMarkdownContent ? (
            <HydrateMdx mdx={decoratedMarkdownContent} />
          ) : (
            markdownCopy
          )}
        </div>
      )}
      {/* eslint-disable-next-line no-nested-ternary */}
      {internalAnchor || externalLink ? (
        internalAnchor ? (
          <Link
            href={internalAnchor}
            passHref
            className={styles.link}
            data-test-id="markdown-icon-block-internal-link"
          >
            {linkText}
            <IconChevronRight className={styles.chevron} />
          </Link>
        ) : (
          <a
            className={styles.link}
            href={externalLink?.url}
            data-test-id="markdown-icon-block-external-link"
          >
            {linkText}
          </a>
        )
      ) : null}
    </div>
  );
};

export default memo(MarkdownIconBlock);
