/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, memo, useState } from 'react';
import { useKeenSlider } from 'keen-slider/react';
import 'keen-slider/keen-slider.min.css';
import { IconChevronLeft, IconChevronRight } from '@nzxt/react-icons';
import { DatoProduct, DatoProductImage } from '@framework/api/types';
import { useMedia, useTranslation as t } from '@utils/hooks';
import Image from '@components/Image';
import SectionWrapper from '@components/SectionWrapper';
import SectionHeading from '@components/SectionHeading';
import ProductDetails from './ProductDetails';
import * as styles from './styles';

type Props = {
  heading?: string;
  subheading?: string;
  productHeading?: string;
  currencyCode?: string;
  featuredProducts: DatoProduct[][];
  lifestyleItems: {
    image: DatoProductImage;
    id?: string | number;
  }[];
};

const ResizePlugin = (slider): void => {
  const observer = new ResizeObserver(() => {
    slider.update();
  });

  slider.on('created', () => {
    observer.observe(slider.container);
  });
  slider.on('destroyed', () => {
    observer.unobserve(slider.container);
  });
};

// TODO: Update Product Panel when we know what we are doing in there.
const LifeStyleCarousel: FC<Props> = ({
  heading,
  subheading,
  productHeading,
  lifestyleItems,
  currencyCode,
  featuredProducts,
}) => {
  const [currentSlide, setCurrentSlide] = useState(1);
  const [loaded, setLoaded] = useState(false);
  const isSm = useMedia('sm');
  const isMd = useMedia('md');
  const MOVE_TO_PREV = t('move_to_previous_label');
  const MOVE_TO_NEXT = t('move_to_next_label');
  const OF_LABEL = t('of');

  const slidesPerViewport = isSm || isMd ? 1.25 : 1.75;

  const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>(
    {
      slides: { perView: slidesPerViewport, spacing: 3, origin: 'center' },
      mode: 'snap',
      loop: true,
      initial: 1,
      slideChanged(s) {
        setCurrentSlide(s.track.details.rel);
      },
      created() {
        setLoaded(true);
      },
    },
    [ResizePlugin]
  );

  const hasProducts =
    Array.isArray(featuredProducts) &&
    featuredProducts?.length > 0 &&
    featuredProducts[currentSlide]?.length > 0;
  const hasLifeStyleItems =
    Array.isArray(lifestyleItems) && lifestyleItems?.length > 0;

  return hasProducts || hasLifeStyleItems ? (
    <SectionWrapper data-test-id="lifestyle-carousel-block">
      {heading && <SectionHeading heading={heading} subheading={subheading} />}

      <div ref={sliderRef} className="keen-slider">
        {lifestyleItems.map((item, i) => (
          <div key={item.id} className="keen-slider__slide">
            <Image
              data={item.image.responsiveImage}
              width={item.image.responsiveImage.width}
              height={item.image.responsiveImage.height}
            />
            {currentSlide === i && hasProducts && (
              <div className={styles.productsWrapper}>
                {productHeading && (
                  <h4 className={styles.productHeading}>{productHeading}</h4>
                )}
                <ul className={styles.productSeparated}>
                  {featuredProducts[currentSlide === i && i]?.map(product => (
                    <ProductDetails
                      currencyCode={currencyCode}
                      name={product.name}
                      slug={product.slug}
                      variants={product.variants}
                      shortDescription={product.shortDescription}
                      key={product.id}
                    />
                  ))}
                </ul>
              </div>
            )}
          </div>
        ))}

        {loaded && instanceRef.current && (
          <>
            <button
              onClick={(e: any): void =>
                e.stopPropagation() || instanceRef.current?.prev()
              }
              type="button"
              className={styles.sliderButton.left}
            >
              <span className={styles.srOnly}>{`${MOVE_TO_PREV} ${
                currentSlide - 1
              }
              ${OF_LABEL} 
                ${lifestyleItems?.length || 0 + 1}
              `}</span>
              <IconChevronLeft className={styles.arrow} />
            </button>
            <button
              type="button"
              onClick={(e: any): void =>
                e.stopPropagation() || instanceRef.current?.next()
              }
              className={styles.sliderButton.right}
            >
              <span className={styles.srOnly}>{`${MOVE_TO_NEXT} ${
                currentSlide + 1
              } ${OF_LABEL} ${lifestyleItems?.length || 0 + 1}`}</span>
              <IconChevronRight className={styles.arrow} />
            </button>
          </>
        )}
      </div>
    </SectionWrapper>
  ) : null;
};

export default memo(LifeStyleCarousel);
