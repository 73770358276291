import { classnames } from '@tailwindcss-classnames';

// padding styles
const leftLg = classnames('lg:pr-20', 'lg:pl-0', 'lg:mx-0', 'lg:max-w-full');
const rightLg = classnames('lg:pl-0', 'lg:pr-20', 'lg:mx-0', 'lg:max-w-full');

export const paddingLg = {
  leftTop: leftLg,
  leftMiddle: leftLg,
  leftBottom: leftLg,
  rightTop: rightLg,
  rightMiddle: rightLg,
  rightBottom: rightLg,
  centerTop: classnames(
    'lg:mb-auto',
    'lg:mt-0',
    'lg:px-0',
    'lg:pt-0',
    'lg:pb-16',
    'lg:max-w-lg'
  ),
  centerMiddle: classnames('lg:px-0', 'lg:py-0', 'lg:max-w-lg'),
  centerBottom: classnames(
    'lg:mb-0',
    'lg:mt-auto',
    'lg:px-0',
    'lg:pb-0',
    'lg:pt-16',
    'lg:max-w-lg'
  ),
};

export const paddingSm = {
  middle: classnames('pl-6', 'pr-6'),
  top: classnames('pl-6', 'pr-6', 'pb-12'),
  bottom: classnames('pl-6', 'pr-6', 'pt-12'),
};

export const paddingLgNoImage = classnames(
  'lg:pt-20',
  'lg:pb-20',
  'lg:pl-20',
  'lg:pr-20'
);

export const paddingLgReset = classnames('lg:px-0', 'lg:py-0');
export const paddingSmReset = classnames('px-0', 'py-0');

// Position Styles
export const largePositionFullbleed = {
  top: classnames('lg:mb-auto', 'lg:mt-0', 'lg:pb-0', 'lg:pt-0'),
  bottom: classnames('lg:mb-0', 'lg:mt-auto', 'lg:pt-0', 'lg:pb-0'),
  default: classnames('lg:mb-auto', 'lg:mt-auto', 'lg:pb-0', 'lg:pt-0'),
};
export const largePosition = {
  top: classnames('lg:mb-auto', 'lg:mt-0', 'lg:pb-16', 'lg:pt-0'),
  bottom: classnames('lg:mb-0', 'lg:mt-auto', 'lg:pt-16', 'lg:pb-0'),
  default: classnames('lg:mb-auto', 'lg:mt-auto', 'lg:pb-16', 'lg:pt-16'),
};

export const smallPositionFullbleed = {
  top: classnames('mb-auto', 'mt-0', 'pb-0'),
  bottom: classnames('mb-0', 'mt-auto', 'pt-0'),
  default: classnames('mb-auto', 'mt-auto', 'pb-0', 'pt-0'),
};
export const smallPosition = {
  top: classnames('mb-auto', 'mt-0', 'pb-6'),
  bottom: classnames('mb-0', 'mt-auto'),
  default: classnames('mb-auto', 'mt-auto'),
};

// image stuff
export const container = classnames('relative', 'lg:w-[48%]');

export const primaryImageContainer = classnames();

export const shortHeight = classnames(
  'md:max-h-[550px]',
  'object-contain',
  'md:max-w-none'
);

export const primaryImageLarge = (isShort: boolean): string =>
  classnames('block', 'max-h-full', 'max-w-full', 'w-full', 'md:mx-auto', {
    [shortHeight]: isShort,
  });
