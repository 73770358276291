/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useMemo, memo } from 'react';
import { MAXIFY_CURRENCY_MAP_PER_REGION } from '@constants';
import usePrice from '@framework/use-price';
import { useRouter } from 'next/router';
import { getRegion } from '@framework/api/utils/maxify';
import { useTranslation as t } from '@utils/hooks';

import * as styles from './styles';

type Props = {
  itemPrice: number;
  oneOfMany?: boolean;
};

// TODO: Remove 'suppressHydrationWarning' from price items
// suppressHydrationWarning is a temporary solution for the server/client mismatch
const CollectionCardPrice: FC<Props> = ({ itemPrice, oneOfMany }) => {
  const { locale } = useRouter();

  const region = getRegion(locale);
  const currencyCode = useMemo(
    () => MAXIFY_CURRENCY_MAP_PER_REGION[region],
    [region]
  );

  const { price } = usePrice({
    amount: itemPrice,
    currencyCode,
  });

  const FROM = t('category_from');

  return (
    <p suppressHydrationWarning className={styles.collectionCardPrice}>
      {oneOfMany ? `${FROM} ${price}` : price}
    </p>
  );
};

export default memo(CollectionCardPrice);
