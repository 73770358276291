import { classnames } from '@tailwindcss-classnames';

export const collectionCardWrapper = classnames(
  'bg-gradient-to-b',
  'from-nzxt-light-grey-50',
  'via-nzxt-light-grey-100',
  'to-nzxt-light-grey-200',
  'hover:shadow-lg',
  'grid',
  'grid-cols-2',
  'p-4',
  'rounded-lg',
  'relative',
  'overflow-hidden',
  'md:mr-2',
  'mt-2',
  'md:mb-0',
  'last:mb-0',
  'first:ml-0',
  'last:mr-0'
);
export const collectionCardTextWrapper = classnames('col-span-1');

export const collectionCardHeader = classnames('xl:text-xl', 'font-bold');
export const collectionCardSubheader = classnames('text-sm', 'xl:text-base');
export const collectionCardPrice = classnames('text-sm', 'xl:text-base');
export const collectionCardImage = classnames('flex-shrink-0', 'w-full');
export const collectionCardSection = classnames('flex', 'flex-wrap');
