import { classnames } from '@tailwindcss-classnames';

const darkCta = classnames(
  'bg-gradient-to-r',
  'from-nzxt-dark-grey-500',
  'via-nzxt-dark-grey-600',
  'to-nzxt-dark-grey-800'
);
const defaultCta = classnames('bg-nzxt-light-grey-100');
const widthMany = classnames('w-full', 'lg:w-1/2');

export const box = (isInverted: boolean, isMany: boolean): string =>
  classnames(
    {
      [darkCta]: isInverted,
      [defaultCta]: !isInverted,
      [widthMany]: isMany,
    },
    'grid',
    'grid-cols-1',
    'gap-2',
    'items-end',
    'justify-items-center',
    'rounded-lg',
    'lg:p-8',
    'p-4',
    'relative',
    'overflow-hidden'
  );

const darkText = classnames('text-nzxt-light-grey-800');
const lightText = classnames('text-white');

export const textBox = (isInverted: boolean): string =>
  classnames(
    {
      [darkText]: !isInverted,
      [lightText]: isInverted,
    },
    'grid',
    'justify-items-center',
    'text-center',
    'md:px-12'
  );

export const descriptionText = classnames('pt-1', 'text-sm');
export const heroHeading = (isInverted: boolean): string =>
  classnames(
    {
      [darkText]: !isInverted,
      [lightText]: isInverted,
    },
    'text-xl',
    'md:text-2xl',
    'lg:text-3xl',
    'font-bold',
    'leading-none'
  );

export const image = classnames('w-full');
export const imageWrapper = classnames('pb-4');

export const button = classnames('w-full', 'ml-0', 'mr-0', 'mt-auto');

export const buttonGroup = classnames(
  'pt-4',
  'text-center',
  'flex',
  'lg:flex-row',
  'flex-col',
  'space-y-2',
  'lg:space-y-0',
  'lg:space-x-4',
  'items-center'
);

export const iconDark = classnames(
  'w-16',
  'h-16',
  'text-white',
  'fill-current'
);
export const iconLight = classnames(
  'w-16',
  'h-16',
  'text-nzxt-light-grey-800',
  'fill-current'
);
export const sectionWrapper = classnames(
  'flex',
  'flex-col',
  'md:flex-row',
  'space-y-4',
  'md:space-x-4',
  'md:space-y-0',
  'mx-auto'
);
