import { classnames } from '@tailwindcss-classnames';

const lightText = classnames('text-white');
const displayCountdown = classnames('opacity-100');
const hideCountdown = classnames('opacity-0');

export const countdownWrapper = (shouldDisplay: boolean): string =>
  classnames(
    'md:w-5/6',
    'lg:w-4/6',
    '2xl:w-1/2',
    'grid',
    'grid-cols-4',
    'gap-2',
    'my-4',
    'transition-opacity',
    'duration-200',
    'ease-in',
    {
      [displayCountdown]: shouldDisplay,
      [hideCountdown]: !shouldDisplay,
    }
  );

export const countdownCell = classnames('text-center');
export const countdownNumber = (isInverted: boolean): string =>
  classnames('text-3xl', 'md:text-4xl', 'lg:text-5xl', 'font-bold', 'mx-auto', {
    [lightText]: !isInverted,
  });

export const countdownText = (isInverted: boolean): string =>
  classnames('uppercase', 'text-xs', {
    [lightText]: !isInverted,
  });
