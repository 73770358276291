/* eslint-disable no-restricted-properties */
import { FC, memo, useRef, useEffect, useState } from 'react';
import { useMedia } from '@utils/hooks';
import type { DatoProductImage } from '@framework/api/types/product';

import ContentWrapper from '@components/ContentWrapper';
import * as styles from '../styles';

type Props = {
  image: DatoProductImage;
  heading?: string;
  leadText?: string;
  bodyCopy?: string;
  isInverted?: boolean;
};

type StyleProps = {
  opacity: string;
  text: string;
  container?: string;
};

const ImageScrolljack: FC<Props> = ({
  image,
  heading,
  leadText,
  bodyCopy,
  isInverted,
}) => {
  const stickyWrapper = useRef<HTMLDivElement>();
  const [scrollPosition, setScrollPosition] = useState(0);
  const [animationState, setAnimationState] = useState(null);
  const [scrollDir, setScrollDir] = useState('down');
  const handleScroll = (): void => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    const threshold = 0;
    let lastScrollY = window.pageYOffset;
    let ticking = false;

    const updateScrollDir = (): void => {
      const scrollY = window.pageYOffset;

      if (Math.abs(scrollY - lastScrollY) < threshold) {
        ticking = false;
        return;
      }
      setScrollDir(scrollY > lastScrollY ? 'down' : 'up');
      lastScrollY = scrollY > 0 ? scrollY : 0;
      ticking = false;
    };

    const onScroll = (): void => {
      if (!ticking) {
        window.requestAnimationFrame(updateScrollDir);
        ticking = true;
      }
    };

    window.addEventListener('scroll', onScroll, { passive: true });

    return () => window.removeEventListener('scroll', onScroll);
  }, [scrollDir]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });
    const { top, height } = stickyWrapper?.current.getBoundingClientRect() ?? {
      top: 0,
      height: 0,
    };
    if (stickyWrapper?.current) {
      const halfContainerHeight = (height * 1.25) / 2;
      const tenPercentContainerHeight = height / 10;

      if (
        scrollDir === 'up' &&
        scrollPosition <= tenPercentContainerHeight + top
      ) {
        setAnimationState('out');
      } else if (
        (scrollDir === 'down' && scrollPosition >= halfContainerHeight + top) ||
        (scrollDir === 'up' && scrollPosition <= halfContainerHeight + top)
      ) {
        setAnimationState('in');
      }
    }
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [scrollPosition, animationState, scrollDir]);

  const getAnimationState = (): StyleProps => {
    if (animationState === 'in') {
      return { opacity: '1', container: styles.animateIn, text: styles.anim };
    }

    if (animationState === 'out') {
      return { opacity: '0', container: styles.animateOut, text: '' };
    }

    return { opacity: '0', container: '', text: '' };
  };

  const isLg = useMedia('lg');

  const altText = image?.responsiveImage?.alt;

  const textStyles = !isLg
    ? {
        right: '80px',
        top: '40%',
      }
    : {
        right: '50px',
        top: '50%',
      };

  return (
    <section ref={stickyWrapper} data-test-id="large-image-scroll-jack-block">
      <ContentWrapper
        style={{
          overflow: 'hidden',
          position: 'relative',
        }}
      >
        {image && (
          <img
            src={image.responsiveImage.src}
            alt={altText}
            style={{ width: '100%' }}
            className={getAnimationState().container}
          />
        )}
        <div
          style={{
            position: 'absolute',
            width: '40%',
            opacity: getAnimationState().opacity,
            transition: 'all 0.3s ease',
            transform: 'translate(0%, -50%)',
            ...textStyles,
          }}
        >
          <div className={getAnimationState().text}>
            {heading && (
              <h2 className={styles.heading(isInverted)}>{heading}</h2>
            )}
            {leadText && (
              <p className={styles.leadText(isInverted)}>{leadText}</p>
            )}
            {bodyCopy && (
              <p className={styles.subheading(isInverted)}>{bodyCopy}</p>
            )}
          </div>
        </div>
      </ContentWrapper>
    </section>
  );
};

export default memo(ImageScrolljack);
