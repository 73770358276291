/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-underscore-dangle */
import { memo, FC } from 'react';
import type { MDXRemoteSerializeResult } from 'next-mdx-remote';
import {
  LinkedItem,
  DatoProductImage,
  ButtonGroup,
} from '@framework/api/types';
import HeroWrapper from '../Components/HeroWrapper';
import HeroCopyBlock from '../Components/HeroCopyBlock';
import HeroImage from '../Components/HeroImage';

type Props = {
  isShort?: boolean;
  // Copy
  isInverted?: boolean;
  eyebrowLogo?: DatoProductImage;
  eyebrow?: string;
  heading?: string;
  subheading?: string;
  copy?: string;
  copyMarkdown?: MDXRemoteSerializeResult;
  buttons?: ButtonGroup[];
  footnote?: string;
  footnoteMarkdown?: MDXRemoteSerializeResult;
  footnoteInternalLink?: LinkedItem;
  // Large Settings
  primaryImageLarge?: DatoProductImage;
  primaryImagePositionLarge?: 'Center' | 'Top' | 'Bottom';
  backgroundImageLarge?: DatoProductImage;
  backgroundImageExtraLarge?: DatoProductImage;
  backgroundColor?: {
    hex: string;
  };
  contentPositionLarge?:
    | 'Left/Top'
    | 'Left/Middle'
    | 'Left/Bottom'
    | 'Right/Top'
    | 'Right/Middle'
    | 'Right/Bottom'
    | 'Center/Top'
    | 'Center/Middle'
    | 'Center/Bottom';
  textAlignmentLarge?: 'Left' | 'Center';
  isFullbleedLarge?: boolean;
  // Small Settings
  primaryImageSmall?: DatoProductImage;
  primaryImagePositionSmall?: 'Center' | 'Top' | 'Bottom';
  backgroundImageSmall?: DatoProductImage;
  contentPositionSmall?: 'Top' | 'Bottom' | 'Middle';
  textAlignmentSmall?: 'Left' | 'Center';
  isFullbleedSmall?: boolean;
};

// TODO: Rename this component as "hero" once we deprecate existing Hero

const HeroBlock: FC<Props> = ({
  isShort,
  isInverted,
  eyebrowLogo,
  eyebrow,
  heading,
  subheading,
  copyMarkdown,
  buttons,
  footnoteMarkdown,
  footnoteInternalLink,
  primaryImageLarge,
  primaryImagePositionLarge,
  backgroundColor,
  backgroundImageLarge,
  backgroundImageExtraLarge,
  isFullbleedLarge = false,
  contentPositionLarge,
  textAlignmentLarge,
  textAlignmentSmall,
  primaryImageSmall,
  primaryImagePositionSmall,
  backgroundImageSmall,
  contentPositionSmall,
  isFullbleedSmall = false,
}) => {
  const ORDER_MAPPER_COPY = {
    'Left/Top': 'order-1',
    'Left/Middle': 'order-1',
    'Left/Bottom': 'order-1',
    'Right/Top': 'order-2',
    'Right/Middle': 'order-2',
    'Right/Bottom': 'order-2',
    'Center/Top': 'order-1',
    'Center/Middle': 'order-1',
    'Center/Bottom': 'order-2',
  };

  const ORDER_MAPPER_COPY_SM = {
    Top: 'order-1',
    Middle: 'order-1',
    Bottom: 'order-2',
  };
  const ORDER_MAPPER_IMAGE = {
    'Left/Top': 'order-2',
    'Left/Middle': 'order-2',
    'Left/Bottom': 'order-2',
    'Right/Top': 'order-1',
    'Right/Middle': 'order-1',
    'Right/Bottom': 'order-1',
    'Center/Top': 'order-2',
    'Center/Middle': 'order-2',
    'Center/Bottom': 'order-1',
  };

  const ORDER_MAPPER_IMAGE_SM = {
    Top: 'order-2',
    Middle: 'order-2',
    Bottom: 'order-1',
  };

  const orderCopy = `${ORDER_MAPPER_COPY_SM[contentPositionSmall]} lg:${ORDER_MAPPER_COPY[contentPositionLarge]}`;
  const orderImage = `${ORDER_MAPPER_IMAGE_SM[contentPositionSmall]} lg:${ORDER_MAPPER_IMAGE[contentPositionLarge]}`;

  return (
    <HeroWrapper
      backgroundColorLarge={backgroundColor?.hex || null}
      backgroundImageSmall={backgroundImageSmall}
      backgroundImageLarge={backgroundImageLarge}
      backgroundImageExtraLarge={backgroundImageExtraLarge}
      contentPositionLarge={contentPositionLarge}
      contentPositionSmall={contentPositionSmall}
      relaxedHeight={Boolean(
        primaryImageLarge?.responsiveImage || primaryImageSmall?.responsiveImage
      )}
      isShort={isShort}
    >
      <HeroCopyBlock
        copyOrder={orderCopy}
        isInverted={isInverted}
        eyebrowLogo={eyebrowLogo}
        eyebrow={eyebrow}
        heading={heading}
        subheading={subheading}
        copyMarkdown={copyMarkdown}
        buttons={buttons}
        footnoteMarkdown={footnoteMarkdown}
        textAlignmentLarge={textAlignmentLarge}
        textAlignmentSmall={textAlignmentSmall}
        footnoteInternalLink={footnoteInternalLink}
        contentPositionLarge={contentPositionLarge}
        contentPositionSmall={contentPositionSmall}
        hasPrimaryImageLg={Boolean(primaryImageLarge?.responsiveImage)}
        isShort={isShort}
      />
      <HeroImage
        orderImage={orderImage}
        primaryImageSmall={primaryImageSmall}
        primaryImagePositionSmall={primaryImagePositionSmall}
        primaryImageLarge={primaryImageLarge}
        primaryImagePositionLarge={primaryImagePositionLarge}
        contentPositionLarge={contentPositionLarge}
        isFullbleedLarge={isFullbleedLarge}
        contentPositionSmall={contentPositionSmall}
        isFullbleedSmall={isFullbleedSmall}
        isShort={isShort}
      />
    </HeroWrapper>
  );
};

export default memo(HeroBlock);
