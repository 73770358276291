/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useMemo, memo } from 'react';
import { getRegion } from '@framework/api/utils/maxify';
import { useRouter } from 'next/router';
import ButtonLink from '@components/ButtonLink';
import { LinkedItem, DatoAvailableLocales } from '@framework/api/types';
import getCmsLinkUrl from '@utils/get-cms-link-url';

import * as styles from './styles';

interface Props {
  heading: string;
  subheading: string;
  externalLink: { url: string };
  internalLink: LinkedItem;
  linkText: string;
  regions?: DatoAvailableLocales[];
}

const LinkBlock: FC<Props> = ({
  heading,
  subheading,
  internalLink,
  externalLink,
  linkText,
  regions,
}) => {
  const internalHref = internalLink
    ? getCmsLinkUrl({
        contentType: internalLink._modelApiKey,
        pageType: internalLink.pageType,
        slug: internalLink.slug,
        parentSlug: internalLink?.parentCategory?.slug,
      })
    : null;

  const { locale } = useRouter();
  const region = getRegion(locale);

  const regionalBlock = useMemo(
    () =>
      Array.isArray(regions) ? regions.some(c => c.region === region) : null,
    [regions, region]
  );

  return regionalBlock ? (
    <div className={styles.linkItemItem}>
      <p className={styles.linkHeading}>{heading}</p>
      <p className={styles.linkSubheading}>{subheading}</p>
      <ButtonLink
        internalLink={Boolean(internalHref)}
        href={internalHref || externalLink.url}
      >
        {linkText}
      </ButtonLink>
    </div>
  ) : null;
};

export default memo(LinkBlock);
