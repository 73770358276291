import { classnames } from '@tailwindcss-classnames';

export const logoGrid = classnames(
  'my-3',
  'flex',
  'flex-wrap',
  'gap-4',
  'lg:gap-x-2',
  'justify-center',
  'content-center',
  '[&>*:nth-child(1)]:w-full',
  'lg:[&>*:nth-child(1)]:w-auto',
  'lg:[&>*]:!order-none'
);
export const logoGridItems = classnames(
  'md:flex',
  'md:items-center',
  'md:mb-0',
  'md:w-[220px]',
  'md:h-[48px]'
);
export const logo = classnames('mx-auto', 'h-[40px]', 'md:h-auto');
