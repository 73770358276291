import { classnames } from '@tailwindcss-classnames';

export const detailsHeading = classnames('h4', 'pb-4');
export const detailsSectionWrapper = classnames(
  'grid',
  'grid-cols-12',
  'gap-8'
);
export const headingLabel = classnames(
  'text-nzxt-light-grey-600',
  'text-xs',
  'pb-4'
);
export const topAddressSection = classnames(
  'grid',
  'grid-cols-1',
  'md:grid-cols-2',
  'gap-4'
);
export const addressColumn = classnames(
  'col-span-12',
  'md:col-span-4',
  'p-4',
  'space-y-3',
  'md:p-0'
);
export const linkColumn = classnames(
  'col-span-12',
  'md:col-span-8',
  'p-4',
  'md:p-6',
  'bg-nzxt-light-grey-100',
  'rounded-lg'
);
export const linkHeading = classnames('font-bold', 'pb-1');
export const linkItemGrid = classnames(
  'grid',
  'lg:grid-cols-4',
  '2xl:grid-cols-6',
  'gap-x-6',
  'gap-y-8'
);
export const linkItemItem = classnames('col-span-2', 'flex', 'flex-col');
export const linkSubheading = classnames('pb-3');

export const link = classnames(
  'underline',
  'mt-auto',
  'hover:text-nzxt-volt-400'
);

export const hoursSection = classnames('pt-10', 'md:pt-20');
