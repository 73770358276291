import { classnames } from '@tailwindcss-classnames';

const widthLimited = classnames('m-auto', 'w-11/12', 'lg:max-w-4xl', 'md:px-6');
const widthDefault = classnames('w-full', 'max-w-full');

export const prose = (useWidthLimit: boolean): string =>
  classnames('prose', {
    [widthLimited]: useWidthLimit,
    [widthDefault]: !useWidthLimit,
  });

export default prose;
