/* eslint-disable no-underscore-dangle */
import { memo, FC } from 'react';
import { LinkedItem } from '@framework/api/types';
import ContentWrapper from '@components/ContentWrapper';

import Cta from './Cta';
import * as styles from './styles';

type CtapProps = {
  heading: string;
  subheading: string;
  buttonLink?: string;
  buttonLabel?: string;
  externalLink?: { url: string };
  internalLink?: LinkedItem[];
  linkText?: string;
  darkcta?: boolean;
  iconComponent: string;
};

type Props = {
  ctaData: CtapProps[];
};

const CtaSection: FC<Props> = ({ ctaData }) =>
  Array.isArray(ctaData) && ctaData?.length > 0 ? (
    <ContentWrapper className={styles.sectionWrapper}>
      {ctaData.map(cta => (
        <Cta key={cta.heading} ctaData={cta} isMany={ctaData?.length > 1} />
      ))}
    </ContentWrapper>
  ) : null;

export default memo(CtaSection);
