import { FC, memo } from 'react';
import { useTranslation as t } from '@utils/hooks';
import type { MDXRemoteSerializeResult } from 'next-mdx-remote';
import HydrateMdx from '@components/HydrateMdx';
import * as styles from '../styles';

interface Props {
  hours: MDXRemoteSerializeResult;
}

const Hours: FC<Props> = ({ hours }) => {
  const SUPPORT_LABEL = t('label_customer_support_hours');
  return (
    <div className={styles.hoursSection}>
      <p className={styles.headingLabel}>{SUPPORT_LABEL}</p>
      {hours ? <HydrateMdx mdx={hours} /> : null}
    </div>
  );
};

export default memo(Hours);
