import { classnames } from '@tailwindcss-classnames';

const darkText = classnames('text-nzxt-light-grey-800');
const lightText = classnames('text-white');
export const lightTextPartial = classnames(
  'text-nzxt-light-grey-800',
  'md:text-white'
);

export const basePartialText = (isInverted: boolean): string =>
  classnames({
    [darkText]: isInverted,
    [lightTextPartial]: !isInverted,
  });
export const centeredText = classnames('text-center', 'mx-auto');
export const defaultText = classnames(
  'mx-auto',
  'text-center',
  'lg:mx-0',
  'lg:text-left'
);
const flexCenter = classnames('justify-center', 'mx-auto', 'items-center');
const flexStart = classnames(
  'justify-center',
  'mx-auto',
  'items-center',
  'lg:justify-start',
  'lg:items-start'
);

export const textColorLight = {
  nzxt: lightText,
  default: lightText,
  green: classnames('text-nzxt-green-400'),
  blue: classnames('text-nzxt-blue-300'),
  red: classnames('text-nzxt-red-300'),
  intel: classnames('text-intel'),
  nvidia: classnames('text-nvidia'),
  amd: classnames('text-amd'),
  yellow: classnames('text-nzxt-yellow-150'),
};

export const textColorLightPartial = {
  nzxt: lightTextPartial,
  default: lightTextPartial,
  green: classnames('text-nzxt-green-400'),
  blue: classnames('text-nzxt-blue-300'),
  red: classnames('text-nzxt-red-300'),
  intel: classnames('text-intel'),
  nvidia: classnames('text-nvidia'),
  amd: classnames('text-amd'),
  yellow: classnames('text-nzxt-yellow-150'),
};

export const textColorDark = {
  nzxt: darkText,
  default: darkText,
  green: classnames('text-nzxt-green-400'),
  blue: classnames('text-nzxt-blue-300'),
  red: classnames('text-nzxt-red-300'),
  intel: classnames('text-intel'),
  nvidia: classnames('text-nvidia'),
  amd: classnames('text-amd'),
  yellow: classnames('text-nzxt-yellow-150'),
};

export const baseText = (isInverted: boolean): string =>
  classnames({
    [darkText]: isInverted,
    [lightText]: !isInverted,
  });

export const eyebrowText = (isCenter: boolean): string =>
  classnames('mb-4', {
    [centeredText]: isCenter,
    [defaultText]: !isCenter,
  });
export const bodyText = classnames(
  'w-4/5',
  'md:w-2/3',
  'xl:w-1/2',
  'border-opacity-50',
  'border-t',
  'mt-4',
  'pt-4'
);

export const headingText = (isCenter: boolean): string =>
  classnames('h2', 'lg:max-w-4xl', 'md:max-w-2xl', {
    [centeredText]: isCenter,
    [defaultText]: !isCenter,
  });

export const buttonContainer = (isCenter: boolean): string =>
  classnames(
    'mt-4',
    'flex',
    'space-y-3',
    'md:space-y-0',
    'md:space-x-2',
    'md:flex-row',
    'flex-col',
    'md:items-center',
    {
      [flexCenter]: isCenter,
      [flexStart]: !isCenter,
    }
  );

const singularButton = classnames('mr-0', 'max-w-min');

export const buttonStyles = (hasManyButtons: boolean): string =>
  classnames({
    [singularButton]: !hasManyButtons,
  });

export const logo = (isCenter: boolean): string =>
  classnames('mb-4', {
    [centeredText]: isCenter,
    [defaultText]: !isCenter,
  });
