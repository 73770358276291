/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
import { FC, memo, useMemo } from 'react';
import cx from 'clsx';
import type { DatoProductImage, ButtonGroup } from '@framework/api/types';
import Image from '@components/Image';
import LinkGroup from '@components/LinkGroup';

import * as styles from './styles';

interface Props {
  eyebrow?: string;
  eyebrowLogo?: DatoProductImage;
  logo?: DatoProductImage;
  heading?: string;
  bodyCopy?: string;
  buttons?: ButtonGroup[];
  textColorTheme?: string;
  darkTheme?: boolean;
  hasMobileVideo?: boolean;
  isCenter?: boolean;
  hasBothVideos?: boolean;
}

const TextBlock: FC<Props> = ({
  eyebrow,
  eyebrowLogo,
  logo,
  heading,
  bodyCopy,
  buttons,
  textColorTheme,
  darkTheme,
  hasMobileVideo,
  hasBothVideos,
  isCenter,
}) => {
  const themeedTextBody = darkTheme
    ? styles.textColorDark[textColorTheme]
    : (!hasMobileVideo && styles.textColorLightPartial[textColorTheme]) ||
      styles.textColorLight[textColorTheme];

  const themeedTextNoVideos = !hasMobileVideo
    ? styles.basePartialText(darkTheme)
    : styles.baseText(darkTheme);

  const themeedTextVideos = hasMobileVideo
    ? styles.baseText(darkTheme)
    : styles.basePartialText(darkTheme);

  const composedClassNameBody = useMemo(
    () =>
      cx(themeedTextBody, styles.bodyText, {
        [styles.centeredText]: isCenter,
        [styles.defaultText]: !isCenter,
      }),
    [isCenter, themeedTextBody]
  );

  const composedClassNameHeading = useMemo(
    () =>
      cx(
        { [themeedTextNoVideos]: !hasBothVideos },
        { [themeedTextVideos]: hasBothVideos || hasMobileVideo },

        styles.headingText(isCenter)
      ),
    [
      isCenter,
      themeedTextVideos,
      themeedTextNoVideos,
      hasBothVideos,
      hasMobileVideo,
    ]
  );

  const composedClassNameEyebrow = useMemo(
    () =>
      cx(
        { [themeedTextNoVideos]: !hasBothVideos },
        { [themeedTextVideos]: hasBothVideos || hasMobileVideo },
        styles.eyebrowText(isCenter)
      ),
    [
      themeedTextVideos,
      themeedTextNoVideos,
      hasBothVideos,
      hasMobileVideo,
      isCenter,
    ]
  );

  return heading || eyebrow || bodyCopy ? (
    <>
      {eyebrowLogo && (
        <div>
          <Image
            data={eyebrowLogo?.responsiveImage}
            width={eyebrowLogo?.responsiveImage?.width}
            height={eyebrowLogo?.responsiveImage?.height}
            className={styles.logo(isCenter)}
            style={{ maxWidth: '200px' }}
          />
        </div>
      )}

      {eyebrow && <p className={composedClassNameEyebrow}>{eyebrow}</p>}
      {heading && (
        <h1
          data-test-id="video-hero-heading"
          className={composedClassNameHeading}
        >
          {heading}
        </h1>
      )}
      {bodyCopy && <p className={composedClassNameBody}>{bodyCopy}</p>}
      {Array.isArray(buttons) && buttons?.length > 0 && (
        <div className={styles.buttonContainer(isCenter)}>
          <LinkGroup
            links={buttons}
            lg
            alignment={isCenter ? 'center' : 'left'}
          />

          {logo && (
            <Image
              data={logo?.responsiveImage}
              width={logo?.responsiveImage?.width}
              height={logo?.responsiveImage?.height}
              className={styles.logo(isCenter)}
              style={{ maxWidth: '100px' }}
            />
          )}
        </div>
      )}
    </>
  ) : null;
};

export default memo(TextBlock);
