import { memo, FC, useMemo } from 'react';
import type {
  DatoAvailableLocales,
  DatoProductImage,
} from '@framework/api/types';
import { getRegion } from '@framework/api/utils/maxify';
import { useRouter } from 'next/router';
import SectionWrapper from '@components/SectionWrapper';
import ContentWrapper from '@components/ContentWrapper';
import SectionHeading from '@components/SectionHeading';
import Logo from './Logo';
import * as styles from './styles';

type Props = {
  regionalLogos?: {
    availableRegions: DatoAvailableLocales[];
    id?: string;
    images?: {
      image?: DatoProductImage;
    }[];
  }[];
  sectionHeading?:
    | {
        eyebrow?: string;
        heading?: string;
        subheading?: string;
      }
    | string;
};

const LogoCloud: FC<Props> = ({ regionalLogos, sectionHeading }) => {
  const hasLogos = Array.isArray(regionalLogos) && regionalLogos?.length > 0;
  const { locale } = useRouter();
  const region = getRegion(locale);

  const regionalLogoData = useMemo(
    () =>
      hasLogos
        ? regionalLogos.filter(c =>
            c.availableRegions?.some(r => r?.region === region)
          )
        : null,
    [regionalLogos, hasLogos, region]
  );

  const copy =
    (Array.isArray(sectionHeading) &&
      sectionHeading?.length > 0 &&
      sectionHeading[0]) ||
    null;

  const hasRegionalLogos =
    Array.isArray(regionalLogoData) && regionalLogoData?.length > 0;

  return hasRegionalLogos ? (
    <SectionWrapper noSpacing>
      <ContentWrapper>
        {copy?.heading || copy?.subheading || copy?.eyebrow ? (
          <SectionHeading
            heading={copy?.heading}
            eyebrow={copy?.eyebrow}
            subheading={copy?.subheading}
            lang={locale}
          />
        ) : null}
        <div className={styles.logoGrid}>
          {regionalLogoData?.map(item => (
            <Logo key={item?.id} logos={item?.images} />
          ))}
        </div>
      </ContentWrapper>
    </SectionWrapper>
  ) : null;
};

export default memo(LogoCloud);
