/* eslint-disable jsx-a11y/anchor-is-valid */
import { memo, FC, useState, useEffect } from 'react';
// import { useRouter } from 'next/router';
// import { getRegion } from '@framework/api/utils/maxify';
import Link from 'next/link';
import usePrice from '@framework/use-price';
import { ROUTE_PRODUCT } from '@constants';
import type { Variant } from '@framework/api/types';

import * as styles from './styles';

// TODO: Clean this up when we know what we are doing with products
type Props = {
  currencyCode: string;
  name: string;
  slug: string;
  variants: Variant[];
  shortDescription?: string;
};

const ProductDetails: FC<Props> = ({
  currencyCode,
  name,
  slug,
  variants,
  shortDescription,
}) => {
  //   const { locale } = useRouter();
  //   const region = getRegion(locale);
  const linkage = `/${ROUTE_PRODUCT}/${slug}`;

  //   const isRegionallyAvailable = useMemo(
  //     () =>
  //       Array.isArray(availableRegions)
  //         ? availableRegions.some(r => r.region === region)
  //         : false,
  //     [region, availableRegions]
  //   );

  const [selectedVariant, setSelectedVariant] = useState(variants[0]);
  const variantsCopy = [...variants];

  useEffect(() => {
    if (variants[0].isOutOfStock || !variants[0]?.isValid) {
      const isInStock = variantsCopy.find(item => !item.isOutOfStock);
      const indexOfInStock = variants.indexOf(isInStock);
      const selectedIndex = indexOfInStock >= 0 ? indexOfInStock : 0;
      setSelectedVariant(variants[selectedIndex]);
    } else {
      setSelectedVariant(variants[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variants]);

  const { price: discoPrice } = usePrice({
    amount: selectedVariant?.discounted_price,
    currencyCode,
  });

  const { price } = usePrice({
    amount: selectedVariant?.price,
    currencyCode,
  });

  //   const isAvailable =
  //     !selectedVariant.isComingSoon &&
  //     !isNude &&
  //     selectedVariant?.isValid &&
  //     isRegionallyAvailable &&
  //     !selectedVariant.isOutOfStock;

  return (
    <li>
      <Link href={linkage} passHref>
        <div className={styles.anchor}>
          {name && (
            <p className={styles.productName} id={`${slug}-heading`}>
              {name}
            </p>
          )}

          <div className={styles.priceContainer}>
            <p
              className={styles.isOnSale(selectedVariant?.isOnSale)}
              id={`${slug}-price`}
            >
              {price}
            </p>
            {selectedVariant?.isOnSale && (
              <p className={styles.discountedPricedText}>{discoPrice}</p>
            )}
          </div>
        </div>
        <p className={styles.productDescription}>{shortDescription}</p>
      </Link>
    </li>
  );
};

export default memo(ProductDetails);
