/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-underscore-dangle */
import { memo, FC } from 'react';
import { screens } from 'tailwindcss/defaultTheme';
import cn from 'clsx';
import { DatoProductImage } from '@framework/api/types';
import Imgix, { Picture, Source } from 'react-imgix';
import * as styles from './styles';

type Props = {
  orderImage?: string;
  // Large Settings
  primaryImageLarge?: DatoProductImage;
  primaryImagePositionLarge?: 'Center' | 'Top' | 'Bottom';
  isFullbleedLarge?: boolean;
  contentPositionLarge?:
    | 'Left/Top'
    | 'Left/Middle'
    | 'Left/Bottom'
    | 'Right/Top'
    | 'Right/Middle'
    | 'Right/Bottom'
    | 'Center/Top'
    | 'Center/Middle'
    | 'Center/Bottom';
  // Small Settings
  primaryImageSmall?: DatoProductImage;
  primaryImagePositionSmall?: 'Center' | 'Top' | 'Bottom';
  contentPositionSmall?: 'Top' | 'Bottom' | 'Middle';
  isFullbleedSmall?: boolean;
  isShort?: boolean;
};

const HeroImage: FC<Props> = ({
  orderImage,
  primaryImageSmall,
  primaryImagePositionSmall,
  primaryImageLarge,
  primaryImagePositionLarge,
  isFullbleedLarge = false,
  contentPositionSmall,
  contentPositionLarge,
  isFullbleedSmall = false,
  isShort = false,
}) => {
  // Padding based off of content flow
  const PADDING_LG = {
    'Left/Top': styles.paddingLg.leftTop,
    'Left/Middle': styles.paddingLg.leftMiddle,
    'Left/Bottom': styles.paddingLg.leftBottom,
    'Right/Top': styles.paddingLg.rightTop,
    'Right/Middle': styles.paddingLg.rightMiddle,
    'Right/Bottom': styles.paddingLg.rightBottom,
    'Center/Top': styles.paddingLg.centerTop,
    'Center/Middle': styles.paddingLg.centerMiddle,
    'Center/Bottom': styles.paddingLg.centerBottom,
  };

  // LARGE VERTICAL ALIGNMENT POSITIONS
  const VERTICAL_POS_LG = {
    Top: styles.largePosition.top,
    Bottom: styles.largePosition.bottom,
    Center: styles.largePosition.default,
  };

  const VERTICAL_POS_FB_LG = {
    Top: styles.largePositionFullbleed.top,
    Bottom: styles.largePositionFullbleed.bottom,
    Center: styles.largePositionFullbleed.default,
  };

  // SMALL VERTICAL ALIGNMENT POSITIONS
  const VERTICAL_POS_SM = {
    Top: styles.smallPosition.top,
    Bottom: styles.smallPosition.bottom,
    Center: styles.smallPosition.default,
  };

  const VERTICAL_POS_FB_SM = {
    Top: styles.smallPositionFullbleed.top,
    Bottom: styles.smallPositionFullbleed.bottom,
    Center: styles.smallPositionFullbleed.default,
  };

  // PADDING AROUND SM IMG
  const PADDING_SM = {
    Middle: styles.paddingSm.middle,
    Top: styles.paddingSm.top,
    Bottom: styles.paddingSm.bottom,
  };

  const largePadding = isFullbleedLarge
    ? styles.paddingLgReset
    : PADDING_LG[contentPositionLarge];
  const smallPadding = isFullbleedSmall
    ? styles.paddingSmReset
    : PADDING_SM[contentPositionSmall];

  const largePosition = isFullbleedLarge
    ? VERTICAL_POS_FB_LG[primaryImagePositionLarge]
    : VERTICAL_POS_LG[primaryImagePositionLarge];

  const smallPosition = isFullbleedSmall
    ? VERTICAL_POS_FB_SM[primaryImagePositionSmall]
    : VERTICAL_POS_SM[primaryImagePositionSmall];

  const spacing = `${smallPadding} ${
    contentPositionLarge?.toLowerCase()?.includes('center') ? 'mx-auto' : ''
  } ${largePadding}`;
  const positions = `${smallPosition} ${largePosition}`;
  const containerStyle = `${spacing} ${positions}`;

  return primaryImageSmall?.responsiveImage &&
    primaryImageLarge?.responsiveImage ? (
    <div className={cn(styles.container, orderImage, containerStyle)}>
      {primaryImageSmall?.responsiveImage && (
        <div
          className={cn(styles.primaryImageContainer)}
          data-test-id="hero-block-primary-image"
        >
          <Picture>
            {primaryImageLarge.responsiveImage.src && (
              <Source
                src={primaryImageLarge.responsiveImage.src}
                width={primaryImageLarge.responsiveImage.width}
                height={primaryImageLarge.responsiveImage.height}
                htmlAttributes={{ media: `(min-width: ${screens.lg})` }}
                imgixParams={{
                  fit: 'max',
                }}
              />
            )}
            {primaryImageSmall?.responsiveImage?.src && (
              <Source
                src={primaryImageSmall.responsiveImage.src}
                width={Math.round(
                  (primaryImageSmall.responsiveImage?.width ?? 0) * 1.5
                )}
                height={Math.round(
                  (primaryImageSmall.responsiveImage?.height ?? 0) * 1.5
                )}
                htmlAttributes={{ media: `(max-width: ${screens.lg})` }}
                imgixParams={{
                  fit: 'max',
                }}
              />
            )}
            {primaryImageSmall?.responsiveImage?.src && (
              <Imgix
                src={primaryImageSmall.responsiveImage.src}
                height={primaryImageSmall.responsiveImage.height}
                width={primaryImageSmall.responsiveImage.width}
                imgixParams={{
                  fit: 'max',
                }}
                className={cn(styles.primaryImageLarge(isShort))}
              />
            )}
          </Picture>
        </div>
      )}
    </div>
  ) : null;
};

export default memo(HeroImage);
