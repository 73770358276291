/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-no-useless-fragment */

import { FC, useMemo, memo } from 'react';
import { useRouter } from 'next/router';
import type { MDXRemoteSerializeResult } from 'next-mdx-remote';
import { getRegion } from '@framework/api/utils/maxify';
import type {
  SimpleCardsProps,
  DatoAvailableLocales,
} from '@framework/api/types';
import LinkColumn from './LinkColumn';
import AddressColumn from './AddressColumn';

type AddressColumnProps = {
  _modelApiKey?: string;
  availableRegions?: DatoAvailableLocales[];
  addressField?: string;
  directorField?: string;
  phoneNumberField?: string;
  hoursField?: string;
  addressFieldMd: MDXRemoteSerializeResult;
  directorFieldMd?: MDXRemoteSerializeResult;
  phoneNumberFieldMd?: MDXRemoteSerializeResult;
  hoursFieldMd?: MDXRemoteSerializeResult;
  holidaySchedule?: string;
  holidayScheduleMd?: MDXRemoteSerializeResult;
  notification?: string;
  notificationMd?: MDXRemoteSerializeResult;
};

interface Props {
  addressColumnData: AddressColumnProps[];
  linkColumnData: SimpleCardsProps[];
}

const DetailsColumns: FC<Props> = ({ addressColumnData, linkColumnData }) => {
  const { locale } = useRouter();
  const region = getRegion(locale);

  const regionalAddressColumn = useMemo(
    () =>
      Array.isArray(addressColumnData)
        ? addressColumnData.find(c =>
            c.availableRegions?.some(r => r.region === region)
          )
        : null,
    [addressColumnData, region]
  );

  return (
    <>
      {addressColumnData.length > 0 && (
        <AddressColumn addressColumnData={regionalAddressColumn} />
      )}
      {linkColumnData.length > 0 && (
        <LinkColumn linkColumnData={linkColumnData} />
      )}
    </>
  );
};

export default memo(DetailsColumns);
