/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/anchor-is-valid */
import type { MDXRemoteSerializeResult } from 'next-mdx-remote';
import { FC, memo } from 'react';
import SectionWrapper from '@components/SectionWrapper';
import ContentWrapper from '@components/ContentWrapper';
import type { SimpleCardsProps } from '@framework/api/types';
import DetailsColumns from './DetailsColumns';
import * as styles from './styles';

type AddressColumnProps = {
  _modelApiKey?: string;
  addressField?: string;
  directorField?: string;
  phoneNumberField?: string;
  hoursField?: string;
  addressFieldMd: MDXRemoteSerializeResult;
  directorFieldMd?: MDXRemoteSerializeResult;
  phoneNumberFieldMd?: MDXRemoteSerializeResult;
  hoursFieldMd?: MDXRemoteSerializeResult;
};

interface Props {
  markdownFields: AddressColumnProps[];
  linkBlocks: SimpleCardsProps[];
  heading:
    | {
        eyebrow?: string;
        heading?: string;
        subheading?: string;
      }
    | string;
}

const DetailsSection: FC<Props> = ({ markdownFields, linkBlocks, heading }) => (
  <SectionWrapper data-test-id="support-block">
    <ContentWrapper>
      {typeof heading === 'string' && (
        <h2
          data-test-id="support-block-heading"
          className={styles.detailsHeading}
        >
          {heading}
        </h2>
      )}
      <div className={styles.detailsSectionWrapper}>
        <DetailsColumns
          addressColumnData={markdownFields}
          linkColumnData={linkBlocks}
        />
      </div>
    </ContentWrapper>
  </SectionWrapper>
);

export default memo(DetailsSection);
