import { FC, memo } from 'react';

type Props = {
  title: string;
  titleStyles?: string;
};

const PageTitle: FC<Props> = ({ title, titleStyles }) => (
  <h1 className={titleStyles}>{title}</h1>
);

export default memo(PageTitle);
