/* eslint-disable no-restricted-properties */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, memo } from 'react';
import cx from 'clsx';
import type { DatoProductImage } from '@framework/api/types/product';
import SectionWrapper from '@components/SectionWrapper';
import ContentWrapper from '@components/ContentWrapper';
import * as styles from '../styles';
import extraStyles from './SmallNoScroll.module.css';

type Props = {
  image: DatoProductImage;
  heading?: string;
  leadText?: string;
  bodyCopy?: string;
  isInverted?: boolean;
};

const SmallNoScroll: FC<Props> = ({
  image,
  heading,
  leadText,
  bodyCopy,
  isInverted,
}) => (
  <SectionWrapper
    className={cx(styles.smallSectionWrapper, extraStyles.textOpacity)}
    style={{
      backgroundImage: image ? `url(${image.responsiveImage.src})` : undefined,
    }}
  >
    <ContentWrapper>
      <div className={styles.smallContentWrapper}>
        {heading && <h2 className={styles.heading(isInverted)}>{heading}</h2>}
        {leadText && <p className={styles.leadText(isInverted)}>{leadText}</p>}
        {bodyCopy && (
          <p className={styles.subheading(isInverted)}>{bodyCopy}</p>
        )}
      </div>
    </ContentWrapper>
  </SectionWrapper>
);

export default memo(SmallNoScroll);
