import { classnames } from '@tailwindcss-classnames';

const headingSpacing = classnames('md:pt-12', 'pt-16');
const defaultSpacing = classnames('md:py-12', 'py-16');

// eslint-disable-next-line import/prefer-default-export
export const proseWrapper = (isHeading: boolean): string =>
  classnames('prose', 'm-auto', {
    [headingSpacing]: isHeading,
    [defaultSpacing]: !isHeading,
  });
