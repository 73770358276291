import { classnames } from '@tailwindcss-classnames';

const containerBound = classnames(
  'w-full',
  'lg:max-w-screen-lg',
  'xl:max-w-screen-xl',
  '2xl:max-w-screen-2xl',
  'mx-auto'
);

export const fullBleed = classnames('h-full');
const defaultSpacing = classnames('mb-6', 'md:mb-0', 'lg:mb-12');

export const innerWrapper = (
  isFullBleed: boolean,
  hasRelatedContent: boolean
): string =>
  classnames('relative', 'overflow-hidden', {
    [fullBleed]: isFullBleed,
    [containerBound]: !isFullBleed,
    [defaultSpacing]: !hasRelatedContent,
  });

export const supportingContent = classnames(
  'mt-6',
  'px-3',
  'md:px-16',
  'lg:px-8',
  'xl:px-16'
);

export const outerWrapper = classnames(
  'w-11/12',
  'lg:max-w-screen-lg',
  'xl:max-w-screen-xl',
  '2xl:max-w-screen-2xl',
  'mx-auto'
);

export const video = classnames(
  'w-full',
  'h-full',
  'object-cover',
  'object-top',
  'md:object-center'
);

export const textPlacement = {
  leftmiddle: classnames('w-1/2', 'top-1/2', '-translate-y-1/2'),
  centercenter: classnames(
    'top-1/2',
    'text-white',
    'left-24',
    'md:left-1/2',
    'md:transform',
    'md:-translate-x-1/2',
    'md:text-center',
    '-translate-y-1/2',
    'w-10/12'
  ),
  centertop: classnames(
    'text-white',
    'left-24',
    'top-52',
    'md:left-1/2',
    'md:transform',
    'md:-translate-x-1/2',
    'md:text-center',
    'w-10/12'
  ),
};

const absoluteComplete = classnames('absolute');
const absolutePartial = classnames('mx-auto', 'md:absolute');

export const textPlacementBase = (hasMobile: boolean): string =>
  classnames({
    [absoluteComplete]: hasMobile,
    [absolutePartial]: !hasMobile,
  });

export const withButtons = classnames(
  'my-auto',
  'flex',
  'flex-col',
  'justify-center',
  'h-full'
);
export const withoutButtons = classnames(
  'my-auto',
  'flex',
  'flex-col',
  'justify-center',
  'h-full'
);

export const textPlacementNoVideoBase = (
  hasButtons: boolean,
  isCenterTop: boolean
): string =>
  classnames({
    [withButtons]: hasButtons && !isCenterTop,
    [withoutButtons]: !hasButtons && !isCenterTop,
  });

export const textPlacementNoVideo = {
  leftmiddle: classnames(
    'absolute',
    'top-1/2',
    '-translate-x-1/2',
    'left-1/2',
    'lg:left-24',
    'transform',
    '-translate-y-1/2',
    'lg:-translate-x-0',
    'w-11/12',
    'lg:w-3/4',
    'max-w-screen-lg',
    'lg:ml-12'
  ),

  centercenter: classnames(
    'absolute',
    'top-1/2',
    'left-1/2',
    'transform',
    '-translate-x-1/2',
    '-translate-y-1/2',
    'md:w-11/12',
    'w-full',
    'px-2',
    'text-center',
    'mx-auto',
    'max-w-screen-lg'
  ),
  centertop: classnames(
    'absolute',
    'top-65',
    'lg:top-52',
    'left-1/2',
    'transform',
    '-translate-x-1/2',
    'md:w-11/12',
    'w-full',
    'px-2',
    'md:px-12',
    'text-center',
    'mx-auto',
    'max-w-screen-lg'
  ),
};

export const overlayAsset = classnames(
  'absolute',
  'bottom-0',
  'right-0',
  'left-0',
  'top-0',
  'w-full',
  'h-full',
  'bg-cover',
  'opacity-75'
);

export const backgroundImageContainer = classnames(
  'absolute',
  'top-0',
  'right-0',
  'left-0',
  'bottom-0',
  'object-cover',
  'h-full',
  'xl:h-auto'
);

export const backgroundImage = classnames('object-cover', 'h-full');
