import { classnames } from '@tailwindcss-classnames';

export const anim = classnames('animate-slideInWithOpacity');

const darkText = classnames('text-nzxt-light-grey-800');
const lightText = classnames('text-white');
export const heading = (isInverted: boolean): string =>
  classnames(
    'text-3xl',
    'md:text-4xl',
    'lg:text-5xl',
    'mb-3',
    { [darkText]: isInverted, [lightText]: !isInverted },
    'font-bold'
  );
export const subheading = (isInverted: boolean): string =>
  classnames({ [darkText]: isInverted, [lightText]: !isInverted }, 'font-thin');
export const leadText = (isInverted: boolean): string =>
  classnames(
    'text-lg',
    'font-bold',
    { [darkText]: isInverted, [lightText]: !isInverted },
    'mb-2'
  );

export const smallContentWrapper = classnames('pb-96', 'pt-8', 'relative');
export const smallSectionWrapper = classnames(
  'bg-center',
  'bg-no-repeat',
  'relative'
);

export const animateIn = classnames('animate-scrollJackIn');
export const animateOut = classnames('animate-scrollJackOut');
