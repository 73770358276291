/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-underscore-dangle */
import { memo, FC, ReactNode } from 'react';
import cn from 'clsx';
import { DatoProductImage } from '@framework/api/types';
import { ResponsiveSource, ResponsiveImage } from '@components/Imgix';
import { screens } from 'tailwindcss/defaultTheme';
import * as styles from './styles';

type Props = {
  isShort?: boolean;
  children?: ReactNode;
  relaxedHeight: boolean;
  // Large Settings
  backgroundImageLarge?: DatoProductImage;
  backgroundImageExtraLarge?: DatoProductImage;
  backgroundColorLarge?: string;
  contentPositionLarge?:
    | 'Left/Top'
    | 'Left/Middle'
    | 'Left/Bottom'
    | 'Right/Top'
    | 'Right/Middle'
    | 'Right/Bottom'
    | 'Center/Top'
    | 'Center/Middle'
    | 'Center/Bottom';
  // Small Settings
  backgroundImageSmall?: DatoProductImage;
  contentPositionSmall?: 'Top' | 'Bottom' | 'Middle';
};

const HeroWrapper: FC<Props> = ({
  isShort = false,
  backgroundColorLarge,
  backgroundImageSmall,
  backgroundImageLarge,
  backgroundImageExtraLarge,
  contentPositionLarge,
  contentPositionSmall,
  relaxedHeight = false,
  children,
}) => {
  const FLOW_MAPPER_DESKTOP = {
    'Left/Top': styles.flowMapperLG.row,
    'Left/Middle': styles.flowMapperLG.row,
    'Left/Bottom': styles.flowMapperLG.row,
    'Right/Top': styles.flowMapperLG.row,
    'Right/Middle': styles.flowMapperLG.row,
    'Right/Bottom': styles.flowMapperLG.row,
    'Center/Top': styles.flowMapperLG.col,
    'Center/Middle': styles.flowMapperLG.col,
    'Center/Bottom': styles.flowMapperLG.col,
  };

  const FLOW_MAPPER_MOBILE = {
    Top: styles.flowMapperSM.col,
    Middle: styles.flowMapperSM.col,
    Bottom: styles.flowMapperSM.col,
  };

  const backgroundColor = backgroundColorLarge
    ? { backgroundColor: backgroundColorLarge }
    : {};

  const contentFlow = `${FLOW_MAPPER_MOBILE[contentPositionSmall]} ${FLOW_MAPPER_DESKTOP[contentPositionLarge]} `;

  const hasBackgroundImageResponsive = Boolean(
    backgroundImageExtraLarge?.responsiveImage &&
      backgroundImageLarge?.responsiveImage &&
      backgroundImageSmall?.responsiveImage
  );

  const hasBackgroundImageNonResponsive = Boolean(
    backgroundImageExtraLarge?.nonResponsiveUrl &&
      backgroundImageLarge?.nonResponsiveUrl &&
      backgroundImageSmall?.nonResponsiveUrl
  );

  const hasBackgroundImage =
    hasBackgroundImageResponsive || hasBackgroundImageNonResponsive;

  const heroHeightMergedClasses = styles.heroHeightClasses(
    isShort,
    relaxedHeight
  );

  return (
    <div
      className={cn(styles.container, heroHeightMergedClasses)}
      style={backgroundColor}
      data-test-id="hero-block-wrapper"
    >
      {hasBackgroundImage && (
        <div className={cn(heroHeightMergedClasses, 'inset-0', 'absolute')}>
          <picture>
            <ResponsiveSource
              imageUrl={backgroundImageExtraLarge.nonResponsiveUrl}
              imgixParams={{ w: 2716, auto: 'format' }}
              screen={screens.xl}
              mediaQueryType="min"
            />
            <ResponsiveSource
              imageUrl={backgroundImageLarge.nonResponsiveUrl}
              imgixParams={{ w: 1536, auto: 'format' }}
              screen={screens.lg}
              mediaQueryType="min"
            />
            <ResponsiveImage
              imageUrl={backgroundImageSmall.nonResponsiveUrl}
              urlType="srcset"
              imgixParams={{ w: 1023, auto: 'format' }}
              className="h-full w-full object-cover"
              height={backgroundImageSmall.height}
              width={backgroundImageSmall.width}
              alt={backgroundImageSmall.alt}
            />
          </picture>
        </div>
      )}
      {children && (
        <div
          className={cn(
            styles.gridWrapper,
            contentFlow,
            heroHeightMergedClasses
          )}
        >
          {children}
        </div>
      )}
    </div>
  );
};

export default memo(HeroWrapper);
