/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, ReactNode, memo } from 'react';
import Link from 'next/link';

type Props = {
  href: string;
  children: ReactNode;
};

const InternalLink: FC<Props> = ({ href, children }) => (
  <Link href={href}>{children}</Link>
);

export default memo(InternalLink);
