/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-underscore-dangle */
import { memo, FC, useEffect } from 'react';
import Router from 'next/router';
import type { MDXRemoteSerializeResult } from 'next-mdx-remote';
import cn from 'clsx';
import {
  DatoProductImage,
  ButtonGroup,
  LinkedItem,
} from '@framework/api/types';
import useUiStore, {
  getOpenModal,
  getSetModalContent,
  getSetModalView,
  getCloseModal,
} from '@stores/use-ui-store';
import HydrateMdx from '@components/HydrateMdx';
import LinkGroup from '@components/LinkGroup';
import RenderImage from '@components/RenderImage';

import * as styles from './styles';

type Props = {
  isShort?: boolean;
  copyOrder?: string;
  hasPrimaryImageLg?: boolean;
  // Copy
  isInverted?: boolean;
  eyebrowLogo?: DatoProductImage;
  eyebrow?: string;
  heading?: string;
  subheading?: string;
  copy?: string;
  copyMarkdown?: MDXRemoteSerializeResult;
  buttons?: ButtonGroup[];
  footnote?: string;
  footnoteMarkdown?: MDXRemoteSerializeResult;
  footnoteInternalLink?: LinkedItem;
  // Large Settings
  textAlignmentLarge?: 'Left' | 'Center';
  contentPositionLarge?:
    | 'Left/Top'
    | 'Left/Middle'
    | 'Left/Bottom'
    | 'Right/Top'
    | 'Right/Middle'
    | 'Right/Bottom'
    | 'Center/Top'
    | 'Center/Middle'
    | 'Center/Bottom';
  // Small Settings
  contentPositionSmall?: 'Top' | 'Bottom' | 'Middle';
  textAlignmentSmall?: 'Left' | 'Center';
};

// TODO: Rename this component as "hero" once we deprecate existing Hero

const HeroCopyBlock: FC<Props> = ({
  isShort = false,
  copyOrder,
  // copy
  isInverted,
  eyebrowLogo,
  eyebrow,
  heading,
  subheading,
  copyMarkdown,
  buttons,
  footnoteMarkdown,
  footnoteInternalLink,
  // large
  textAlignmentLarge,
  contentPositionLarge,
  // small
  textAlignmentSmall,
  contentPositionSmall,
}) => {
  // Handling for the Notice Page modal
  const setModalView = useUiStore(getSetModalView);
  const setModalContent = useUiStore(getSetModalContent);
  const openModal = useUiStore(getOpenModal);
  const closeModal = useUiStore(getCloseModal);

  const handleNoticeModal = (markdownContent, header): void => {
    setModalView('NOTICE_PAGE');
    setModalContent({ markdownCopy: header, markdownContent });
    openModal();
  };
  useEffect(() => {
    Router.events.on('routeChangeComplete', () => closeModal());

    return () => {
      Router.events.off('routeChangeComplete', () => closeModal());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Class Construction for Positions of Copy
  const POSITIONS_LG = {
    'Left/Top': styles.topLg,
    'Left/Middle': styles.middleLg,
    'Left/Bottom': styles.bottomLg,
    'Right/Top': styles.topLg,
    'Right/Middle': styles.middleLg,
    'Right/Bottom': styles.bottomLg,
    'Center/Top': styles.topLg,
    'Center/Middle': styles.middleLg,
    'Center/Bottom': styles.bottomLg,
  };

  const POSITIONS_SM = {
    Middle: styles.middle,
    Top: styles.top,
    Bottom: styles.bottom,
  };

  const PADDING_LG = {
    'Left/Top': styles.paddingLg.leftTop,
    'Left/Middle': styles.paddingLg.leftMiddle,
    'Left/Bottom': styles.paddingLg.leftBottom,
    'Right/Top': styles.paddingLg.rightTop,
    'Right/Middle': styles.paddingLg.rightMiddle,
    'Right/Bottom': styles.paddingLg.rightBottom,
    'Center/Top': styles.paddingLg.centerTop,
    'Center/Middle': styles.paddingLg.centerMiddle,
    'Center/Bottom': styles.paddingLg.centerBottom,
  };

  const PADDING_SM = {
    Middle: styles.paddingSm(isShort).middle,
    Top: styles.paddingSm(isShort).top,
    Bottom: styles.paddingSm(isShort).bottom,
  };

  // COPY ALIGNMENT

  const TEXT_ALIGN_MAPPER = {
    Center: styles.textAlignMapper.center,
    Left: styles.textAlignMapper.left,
  };

  const MARGIN_ALIGN = {
    Center: styles.margin.center,
    Left: styles.margin.left,
  };

  const eyebrowLogoMargin = `${MARGIN_ALIGN[textAlignmentSmall]} lg:${MARGIN_ALIGN[textAlignmentLarge]}`;

  const paddingClasses = `${PADDING_SM[contentPositionSmall]} ${PADDING_LG[contentPositionLarge]}`;
  const textAlignment = `${TEXT_ALIGN_MAPPER[textAlignmentSmall]} lg:${TEXT_ALIGN_MAPPER[textAlignmentLarge]}`;
  const copyPos = `${POSITIONS_SM[contentPositionSmall]} ${POSITIONS_LG[contentPositionLarge]}`;
  const copyPositions = `${copyPos} ${paddingClasses} ${textAlignment}`;

  return (
    <div className={cn(styles.contentContainer, copyPositions, copyOrder)}>
      {eyebrow && (
        <p
          className={styles.eyebrow(isInverted)}
          data-test-id="hero-copy-block-eyebrow"
        >
          {eyebrow}
        </p>
      )}
      {eyebrowLogo && (
        <RenderImage
          className={cn(styles.eyebrowLogo, eyebrowLogoMargin)}
          image={eyebrowLogo}
          dataTestId="hero-copy-block-eyebrow-logo"
        />
      )}
      {heading && (
        <h1
          className={styles.heading(isInverted)}
          data-test-id="hero-copy-block-heading"
        >
          {heading}
        </h1>
      )}
      {subheading && (
        <p
          className={styles.subheading(isInverted)}
          data-test-id="hero-copy-block-subheading"
        >
          {subheading}
        </p>
      )}
      {copyMarkdown && (
        <div
          className={cn(styles.copy(isInverted), eyebrowLogoMargin)}
          data-test-id="hero-copy-block-md"
        >
          <HydrateMdx mdx={copyMarkdown} />
        </div>
      )}
      {Array.isArray(buttons) && buttons?.length > 0 && (
        <div className={styles.ctaContainer}>
          <div
            className={cn(
              styles.buttonWrapperLg(
                textAlignmentLarge.toLowerCase() === 'center'
              )
            )}
            data-test-id="hero-button-block-lg"
          >
            <LinkGroup
              links={buttons}
              lg
              isInverted={isInverted}
              alignment={textAlignmentLarge.toLowerCase() as 'center' | 'left'}
            />
          </div>
          <div
            className={cn(
              styles.buttonWrapperSm(
                textAlignmentSmall.toLowerCase() === 'center'
              )
            )}
            data-test-id="hero-button-block-sm"
          >
            <LinkGroup
              links={buttons}
              lg
              isInverted={isInverted}
              alignment={textAlignmentSmall.toLowerCase() as 'center' | 'left'}
            />
          </div>
        </div>
      )}
      {footnoteMarkdown && (
        <>
          {footnoteInternalLink?.contentMarkdown ? (
            <button
              type="button"
              className={cn(styles.footnote(isInverted, true), textAlignment)}
              onClick={() =>
                handleNoticeModal(
                  footnoteInternalLink?.contentMarkdown,
                  footnoteInternalLink?.heading
                )
              }
              data-test-id="hero-copy-block-footnote-button"
            >
              <HydrateMdx mdx={footnoteMarkdown} />
            </button>
          ) : (
            <div
              className={styles.footnote(isInverted, false)}
              data-test-id="hero-copy-block-footnote"
            >
              <HydrateMdx mdx={footnoteMarkdown} />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default memo(HeroCopyBlock);
