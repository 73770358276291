/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-no-useless-fragment */
import { FC, memo } from 'react';
import ContentWrapper from '@components/ContentWrapper';
import type { DatoProduct } from '@framework/api/types';
import CollectionCardInner from './CollectionCardInner';
import * as styles from './styles';

interface CollectionCardProps extends DatoProduct {
  collectionName: string;
  collectionSlug: string;
}

type Props = {
  collectionData: CollectionCardProps[] | CollectionCardProps[][];
};

const CollectionCardSection: FC<Props> = ({ collectionData }) => (
  <>
    {Array.isArray(collectionData) && collectionData?.length > 0 && (
      <ContentWrapper
        className={styles.collectionCardSection}
        data-test-id="marketing-product-card-block"
      >
        {collectionData.map((item, i) =>
          item ? (
            <CollectionCardInner
              key={`${item[0]?.name}-${i}`}
              product={item}
              dataLength={collectionData.length}
            />
          ) : null
        )}
      </ContentWrapper>
    )}
  </>
);

export default memo(CollectionCardSection);
