/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-underscore-dangle */
import { memo, FC } from 'react';
import {
  IconHeartWithRays,
  IconPcSystem,
  IconComputerCase,
} from '@nzxt/react-icons';
import { LinkedItem } from '@framework/api/types';
import getCmsLinkUrl from '@utils/get-cms-link-url';
import ButtonLink from '@components/ButtonLink';
import * as styles from './styles';

type CtaProps = {
  heading: string;
  subheading: string;
  buttonLink?: string;
  buttonLabel?: string;
  externalLink?: { url: string };
  internalLink?: LinkedItem[];
  linkText?: string;
  darkcta?: boolean;
  iconComponent?: string;
};

type Props = {
  ctaData: CtaProps;
  isMany?: boolean;
};
const { iconLight } = styles;
const { iconDark } = styles;

const ICON_HIGHLIGHT_MAP_LIGHT = {
  HeartWithRays: <IconHeartWithRays className={iconLight} />,
  ComputerCase: <IconComputerCase className={iconLight} />,
  PcSystem: <IconPcSystem className={iconLight} />,
};

const ICON_HIGHLIGHT_MAP_DARK = {
  HeartWithRays: <IconHeartWithRays className={iconDark} />,
  ComputerCase: <IconComputerCase className={iconDark} />,
  PcSystem: <IconPcSystem className={iconDark} />,
};

const Cta: FC<Props> = ({ ctaData, isMany = false }) => {
  const internalLink = ctaData.internalLink
    ? ctaData.internalLink.map(intLink => {
        const link = getCmsLinkUrl({
          contentType: intLink._modelApiKey,
          pageType: intLink.pageType,
          slug: intLink.slug,
          parentSlug: intLink?.parentCategory?.slug,
        });

        return link;
      })
    : null;

  return (
    <div
      className={styles.box(ctaData.darkcta, isMany)}
      data-test-id="cta-subblock"
    >
      {ctaData?.iconComponent && (
        <div className={styles.imageWrapper}>
          {ctaData.darkcta
            ? ICON_HIGHLIGHT_MAP_DARK[ctaData.iconComponent]
            : ICON_HIGHLIGHT_MAP_LIGHT[ctaData.iconComponent]}
        </div>
      )}
      <div className={styles.textBox(ctaData.darkcta)}>
        <h3 className={styles.heroHeading(ctaData.darkcta)}>
          {ctaData.heading}
        </h3>
        <p className={styles.descriptionText}>{ctaData.subheading}</p>
      </div>
      <div className={styles.buttonGroup}>
        {Array.isArray(internalLink) && internalLink.length > 0 ? (
          <ButtonLink
            asButton
            dark={ctaData.darkcta}
            buttonStyle="secondary"
            href={internalLink[0]}
            internalLink
            className={styles.button}
            aria-label={`${ctaData.linkText} ${process.env.NEXT_PUBLIC_BASE_URL}${internalLink[0]}`}
          >
            {ctaData.linkText}
          </ButtonLink>
        ) : (
          <>
            {ctaData.externalLink && (
              <ButtonLink
                asButton
                buttonStyle="secondary"
                href={ctaData.externalLink.url}
                className={styles.button}
                dark={!ctaData.darkcta}
                aria-label={`${ctaData.linkText} ${process.env.NEXT_PUBLIC_BASE_URL}${ctaData.linkText}`}
              >
                {ctaData.linkText}
              </ButtonLink>
            )}
          </>
        )}
        {ctaData.buttonLink && ctaData.buttonLabel && (
          <ButtonLink
            asButton
            buttonStyle="secondary"
            href={ctaData.buttonLink}
            internalLink
            className={styles.button}
            dark={ctaData.darkcta}
            aria-label={`${ctaData.buttonLabel} ${process.env.NEXT_PUBLIC_BASE_URL}${ctaData.buttonLink}`}
          >
            {ctaData.buttonLabel}
          </ButtonLink>
        )}
      </div>
    </div>
  );
};

export default memo(Cta);
