import { classnames } from '@tailwindcss-classnames';

export const wrapper = classnames(
  'px-3',
  'bg-nzxt-light-grey-50',
  'md:px-8',
  'pt-1',
  'relative',
  'group'
);

export const link = classnames('flex', 'items-center', 'flex-col');

export const imageWrapper = classnames('pb-2');

export const textWrapper = classnames('text-sm');

const buttonBase = classnames(
  'absolute',
  'top-1/2',
  '-translate-y-1/2',
  'bg-nzxt-light-grey-600',
  'rounded-full',
  'p-1.5',
  'transition-all',
  'duration-700',
  'opacity-0',
  'scale-0',
  'group-hover:opacity-75',
  'group-hover:scale-100'
);

export const buttonPrev = classnames(buttonBase, 'left-0');

export const buttonNext = classnames(buttonBase, 'right-0');

export const chevron = classnames('text-white', 'text-xl');
