import { FC, memo, ReactNode } from 'react';
import * as styles from './styles';

type Props = {
  children: ReactNode;
  isHeading?: boolean;
};

const ProseWrapper: FC<Props> = ({ children, isHeading = false }) => (
  <div className={styles.proseWrapper(isHeading)}>{children}</div>
);

export default memo(ProseWrapper);
