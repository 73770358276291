/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, memo } from 'react';
import Link from 'next/link';
import type { DatoProductImage } from '@framework/api/types';
import { useMedia } from '@utils/hooks';

import { ROUTE_COLLECTION } from '@constants';
import Image from '@components/Image';

import CollectionCardPrice from './CollectionCardPrice';
import * as styles from './styles';

interface Props {
  collectionName: string;
  collectionSlug: string;
  lowestPriceInCollection: number;
  isOneOfMany?: boolean;
  dataLength: number;
  isRegionallyAvailable?: boolean;
  collectPageMainImage: DatoProductImage;
}

const getCardWidth = (isSm, isMd, isLg, length): string => {
  switch (true) {
    case isSm:
      return `100%`;

    case isMd:
      return `calc((100% / 2) - 8px)`;

    case isLg:
      return `calc((100% / ${length}) - 8px)`;

    default:
      return `calc((100% / ${length}) - 8px)`;
  }
};

const CollectionCard: FC<Props> = ({
  collectionName,
  collectionSlug,
  lowestPriceInCollection,
  isOneOfMany,
  dataLength,
  collectPageMainImage,
  isRegionallyAvailable,
}) => {
  const SM = useMedia('sm');
  const MD = useMedia('md');
  const isLg = !SM && !MD;
  const width = getCardWidth(SM, MD, isLg, dataLength);
  return (
    <Link
      passHref
      href={`/${ROUTE_COLLECTION}/${collectionSlug}`}
      className={styles.collectionCardWrapper}
      style={{
        width,
        minHeight: '150px',
      }}
      data-test-id="collection-card-item"
    >
      <div className={styles.collectionCardTextWrapper}>
        <h3
          className={styles.collectionCardHeader}
          data-test-id="collection-card-item-header"
        >
          {collectionName}
        </h3>

        {isRegionallyAvailable && (
          <CollectionCardPrice
            itemPrice={lowestPriceInCollection}
            oneOfMany={isOneOfMany}
          />
        )}
      </div>
      <div className={styles.collectionCardImage}>
        <Image
          data={collectPageMainImage.responsiveImage}
          width={collectPageMainImage.responsiveImage.width}
          height={collectPageMainImage.responsiveImage.height}
          className={styles.collectionCardImage}
        />
      </div>
    </Link>
  );
};

export default memo(CollectionCard);
