/* eslint-disable react/jsx-no-useless-fragment */
import { FC, memo } from 'react';
import type { DatoProductImage, ButtonGroup } from '@framework/api/types';
import Image from '@components/Image';
import LinkGroup from '@components/LinkGroup';
import Countdown from '../Countdown';

import * as styles from './styles';

interface Props {
  asH1?: boolean;
  eyebrow?: string;
  eyebrowLogo?: DatoProductImage;
  heading?: string;
  subheading?: string;
  id?: string;
  buttons?: ButtonGroup[];
  date?: string;
  darkText?: boolean;
  hasPrimaryAsset?: boolean;
  hideCountdown?: boolean;
}

const TextBlock: FC<Props> = ({
  eyebrowLogo,
  eyebrow,
  heading,
  subheading,
  asH1 = false,
  buttons,
  date,
  darkText = false,
  hasPrimaryAsset = false,
  hideCountdown = false,
}) => {
  const hasButtons =
    Array.isArray(buttons) && buttons?.filter(butt => butt?.linkLabel);
  return heading || subheading ? (
    <div className={styles.textBlockWrapper(hasPrimaryAsset)}>
      {eyebrowLogo && (
        <Image
          data={eyebrowLogo?.responsiveImage}
          width={eyebrowLogo?.responsiveImage?.width}
          height={eyebrowLogo?.responsiveImage?.height}
          style={{ maxWidth: '200px' }}
          className={styles.eyebrowLogo}
          loading="eager"
          decoding="async"
        />
      )}
      {eyebrow && <p className={styles.eyebrow(darkText)}>{eyebrow}</p>}
      {heading && (
        <>
          {asH1 ? (
            <h1
              className={styles.heading(darkText)}
              data-test-id="countdown-hero-text-block-heading1"
            >
              {heading}
            </h1>
          ) : (
            <h2
              className={styles.heading(darkText)}
              data-test-id="countdown-hero-text-block-heading2"
            >
              {heading}
            </h2>
          )}
        </>
      )}
      {subheading && (
        <p
          className={styles.subheading(darkText)}
          data-test-id="countdown-hero-text-block-subheading"
        >
          {subheading}
        </p>
      )}

      {!hideCountdown && date && (
        <Countdown date={date} isInverted={darkText} />
      )}
      {hasButtons && <LinkGroup links={buttons} alignment="left" />}
    </div>
  ) : null;
};
export default memo(TextBlock);
