import { FC, memo } from 'react';
import type { MDXRemoteSerializeResult } from 'next-mdx-remote';
import HydrateMdx from '@components/HydrateMdx';

interface Props {
  markdown: MDXRemoteSerializeResult;
}

const MarkdownField: FC<Props> = ({ markdown }) =>
  markdown ? (
    <div>
      <HydrateMdx mdx={markdown} />
    </div>
  ) : null;

export default memo(MarkdownField);
