import { classnames } from '@tailwindcss-classnames';

export const heading = classnames('h6', 'font-bold');
export const link = classnames(
  'text-nzxt-volt-400',
  'hover:underline',
  'flex',
  'items-center'
);
export const buildSystem = classnames(
  'w-12',
  'h-12',
  'text-nzxt-dark-grey-500',
  'fill-current'
);

export const chevron = classnames('h-4', 'w-4');
export const contentWrapperExtended = classnames(
  'flex',
  'flex-col',
  'md:flex-row',
  'justify-center',
  'space-y-8',
  'md:space-y-0'
);

export const content = classnames('pb-6');

const minimalOuter = classnames('w-full', 'md:w-1/2', 'mx-auto');
const defaultOuter = classnames(
  'w-full',
  'md:w-2/5',
  'first:mr-0',
  'last:ml-0',
  'md:first:mr-8',
  'md:last:ml-8'
);

export const blockOuter = (isMoreThanOne: boolean): string =>
  classnames('space-y-3', {
    [minimalOuter]: !isMoreThanOne,
    [defaultOuter]: isMoreThanOne,
  });
