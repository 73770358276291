/* eslint-disable no-underscore-dangle */
import { memo, FC, useState, useEffect, useCallback } from 'react';
import { useKeenSlider } from 'keen-slider/react';
import debounce from 'lodash.debounce';
import { IconChevronLeft, IconChevronRight } from '@nzxt/react-icons';
import 'keen-slider/keen-slider.min.css';
import Link from 'next/link';
import getCmsLinkUrl from '@utils/get-cms-link-url';
import { useTranslation as t } from '@utils/hooks';
import { useHistory } from '@utils/history';
import { ResponsiveImage } from '@components/Imgix';
import { track } from '@amplitude/analytics-browser';
import * as styles from './styles';

type PageMeta = {
  image: {
    responsiveImage: {
      src: string;
    };
  };
};

type Links = {
  _modelApiKey: string;
  id: string;
  name: string;
  title?: string;
  navTitle?: string;
  pageMeta: PageMeta;
  pageType?: string;
  slug: string;
  parentCategory?: {
    slug: string;
  };
};

type Props = {
  items: {
    id?: string | number;
    links: Links[];
  };
  handleSelect?: (i) => void;
  activeItem?: number;
};

const Scroller: FC<Props> = ({ items }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [width, setWidth] = useState(null);
  const [isEnabled, setIsEnabled] = useState(false);

  const MOVE_TO_PREV = t('move_to_previous_label');
  const MOVE_TO_NEXT = t('move_to_next_label');

  const { last: lastPage } = useHistory();

  const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>({
    slides: { perView: 'auto' },
    mode: 'free-snap',
    loop: false,
    detailsChanged(slider) {
      const enabled = slider.track.details.length > 0;
      setIsEnabled(enabled);
    },
    slideChanged(s) {
      setCurrentSlide(s.track.details.rel);
    },
    created() {
      setLoaded(true);
    },
  });

  const handleLinkClick = useCallback(
    (name: string) => {
      if (typeof window === 'undefined') return;
      const eventProperties = {
        'page source url': window.location.href,
        page_path: window.location.pathname,
        'referrer source url': lastPage || '',
        'item selected': name,
      };
      track('category carousel item clicked', eventProperties);
    },
    [lastPage]
  );

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const handleResize = debounce(() => setWidth(window.innerWidth), 100);

      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
  }, []);

  // Fix the fact that keen slider refuses to update per viewport
  const resetSlide = (): void => {
    if (instanceRef?.current) {
      instanceRef.current?.update();
    }
  };
  const debouncedUnfocus = debounce(resetSlide, 100);
  useEffect(() => {
    if (instanceRef?.current) {
      debouncedUnfocus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instanceRef, width]);

  const isAtBeginning = currentSlide === 0;
  const isAtEnd =
    instanceRef.current?.track.details.abs ===
    instanceRef.current?.track.details.maxIdx;

  return (
    <div className={styles.wrapper}>
      <div
        ref={sliderRef}
        className={`keen-slider ${
          isEnabled ? 'justify-start' : 'justify-center'
        }`}
      >
        {items.links.map((item, i) => {
          const link = getCmsLinkUrl({
            contentType: item?._modelApiKey,
            pageType: item?.pageType,
            slug: item?.slug,
            parentSlug: item?.parentCategory?.slug,
          });

          return item?.pageMeta?.image?.responsiveImage?.src ? (
            <div
              key={item.id}
              className={`keen-slider__slide number-slide${i}`}
              style={{
                minWidth: 106,
                width: 'auto',
                flex: '0 0 auto',
                padding: '4px 28px 14px',
              }}
            >
              <Link
                className={styles.link}
                href={link}
                onClick={() => handleLinkClick(item.name)}
              >
                <div className={styles.imageWrapper}>
                  <ResponsiveImage
                    imageUrl={item?.pageMeta?.image?.responsiveImage?.src}
                    imgixParams={{ w: 80, h: 80, auto: 'format' }}
                    height={80}
                    width={80}
                    urlType="srcset"
                    alt={item.name || ''}
                    className="w-20"
                  />
                </div>
                <div className={styles.textWrapper}>
                  {item.navTitle || item.name || item.title}
                </div>
              </Link>
            </div>
          ) : null;
        })}
        {loaded && isEnabled && (
          <>
            {!isAtBeginning && (
              <button
                type="button"
                className={styles.buttonPrev}
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                onClick={(e: any) =>
                  e.stopPropagation() || instanceRef.current?.prev()
                }
              >
                <span className="sr-only">{MOVE_TO_PREV}</span>
                <IconChevronLeft className={styles.chevron} />
              </button>
            )}
            {!isAtEnd && (
              <button
                type="button"
                className={styles.buttonNext}
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                onClick={(e: any) =>
                  e.stopPropagation() || instanceRef.current?.next()
                }
              >
                <span className="sr-only">{MOVE_TO_NEXT}</span>
                <IconChevronRight className={styles.chevron} />
              </button>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default memo(Scroller);
